import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap"
import { Link, Navigate } from "react-router-dom"
import { post, del, get, put } from "../../helpers/api_helper"
// Custom Scrollbar
import SimpleBar from "simplebar-react"

// import images
import servicesIcon1 from "../../assets/images/services-icon/01.png"
import servicesIcon2 from "../../assets/images/services-icon/02.png"
import servicesIcon3 from "../../assets/images/services-icon/03.png"
import servicesIcon4 from "../../assets/images/services-icon/04.png"
import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"
import user4 from "../../assets/images/users/user-4.jpg"
import user5 from "../../assets/images/users/user-5.jpg"
import user6 from "../../assets/images/users/user-6.jpg"
import smimg1 from "../../assets/images/small/img-1.jpg"
import smimg2 from "../../assets/images/small/img-2.jpg"

// Charts
import { useNavigate } from "react-router-dom"
import LineAreaChart from "../AllCharts/apex/lineareachart"
import RadialChart from "../AllCharts/apex/apexdonut"
import Apexdonut from "../AllCharts/apex/apexdonut1"
import SparkLine from "../AllCharts/sparkline/sparkline"
import SparkLine1 from "../AllCharts/sparkline/sparkline1"
import Salesdonut from "../AllCharts/apex/salesdonut"

import "chartist/dist/scss/chartist.scss"
import MiniStatCard from "../../components/Common/Ministat"
//i18n
import { withTranslation } from "react-i18next"
import { data } from "pages/AllCharts/chartjs/linechart"

import BannerCarosell from "./BannerCarosell"

const Dashboard = props => {
  const [earnings, setEarnings] = useState(null)
  const [bookings, setBookings] = useState(null)
  const [drivers, setDrivers] = useState(null)
  const [lastMonthEarning, setlastMonthEarning] = useState(null)
  const [thisMonthEarning, setthisMonthEarning] = useState(null)
  const [vehicles, setVehicles] = useState(null)
  const [menu, setMenu] = useState(false)
  const[providerId, setProviderId] = useState(null)
  const toggle = () => {
    setMenu(!menu)
  }

 function getUser(){
  const userString = localStorage.getItem("authUser");
  const user = userString ? JSON.parse(userString) : null;
  console.log("prom", user);
  setProviderId(user?.provider_id);
 }

  function getEarnings() {
    get("/dashboard/totalearnings?providerId="+providerId).then(response =>
      setEarnings(response.totalEarnings)
    )
  }

  function getbookings() {
    get("/dashboard/totalbookings?providerId="+providerId).then(response =>
      setBookings(response.totalBookings)
    )
  }

  function getdrivers() {
    get("/dashboard/totaldrivers?providerId="+providerId).then(response =>
      setDrivers(response.totalDrivers)
    )
  }

  function getvehicles() {
    get("/dashboard/totalvehicles?providerId="+providerId).then(response =>
      setVehicles(response.totalVehicles)
    )
  }

  function getLastMonthEarning() {
    get("/dashboard/lastmonthearnings?providerId="+providerId).then(response =>
      setlastMonthEarning(response.totalEarningsByLastMonth)
    )
  }

  function getthisMonthEarning() {
    get("/dashboard/thismonthearnings?providerId="+providerId).then(response =>
      setthisMonthEarning(response.totalEarningsByThisMonth)
    )
  }

  // function getmanagers() {
  //   get("/dashboard/totalmanagers", {
  //     params: {
  //       providerId: authUser?.provider_id,
  //     },
  //   }).then(response => setManagers(response.totalmanagers))
  // }
  useEffect(() => {
    getUser()
    if (providerId != null){
    getEarnings()
    getbookings()
    getvehicles()
    getthisMonthEarning()
    getLastMonthEarning()
    getdrivers()
    }
  }, [providerId])

  document.title = "Dashboard | hearts - React Admin & Dashboard  "
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Dashboard</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Welcome to hearts Dashboard
                  </li>
                </ol>
              </Col>

              <Col md="4">
                <div className="float-end d-none d-md-block"></div>
              </Col>
            </Row>
          </div>

          <Row>
            <Col xl={3} md={6}>
              <MiniStatCard
                icon={servicesIcon1}
                title="Bookings"
                value={bookings}
                change="+ 12%"
                changeDirection="up"
                link="#"
              />
            </Col>
            <Col xl={3} md={6}>
              <MiniStatCard
                icon={servicesIcon2}
                title="Totat Earnings"
                value={`${earnings} BIRR`}
                changeDirection="down"
                link="#"
              />
            </Col>

            <Col xl={3} md={6}>
              <MiniStatCard
                icon={servicesIcon4}
                title="Total Drivers"
                value={drivers}
                change="+ 84%"
                changeDirection="up"
                link="#"
              />
            </Col>
            <Col xl={3} md={6}>
              <MiniStatCard
                icon={servicesIcon3}
                title="Total Vehicles"
                value={vehicles}
                changeDirection="up"
                link="#"
              />
            </Col>
          </Row>

          <Row>
            <BannerCarosell />
          </Row>
          {/* monthly earnings */}
          {/* <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Monthly Earning</h4>
                  <Row>
                    <Col lg={7}>
                      <div>
                        <LineAreaChart />
                      </div>
                    </Col>
                    <Col lg={5}>
                      <Row>
                        <Col md={6}>
                          <div className="text-center">
                            <p className="text-muted mb-4">This month</p>
                            <h3>{thisMonthEarning}</h3>
                            <p className="text-muted mb-4">
                              It will be as simple as in fact it will be
                              occidental.
                            </p>
                            <RadialChart />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="text-center">
                            <p className="text-muted mb-4">Last month</p>
                            <h3>{lastMonthEarning}</h3>
                            <p className="text-muted mb-4">
                              It will be as simple as in fact it will be
                              occidental.
                            </p>
                            <Apexdonut />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            {/* SparkLine1   line chart zigzag */}
          {/* <Col xl={3}>
              <Card>
                <CardBody>
                  <div>
                    <h4 className="card-title mb-4">Sales Analytics</h4>
                  </div>
                  <div className="wid-peity mb-4">
                    <div className="row">
                      <div className="col-md-6">
                        <div>
                          <p className="text-muted">Online</p>
                          <h5 className="mb-4">1,542</h5>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <SparkLine />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="wid-peity mb-4">
                    <div className="row">
                      <div className="col-md-6">
                        <div>
                          <p className="text-muted">Offline</p>
                          <h5 className="mb-4">6,451</h5>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <SparkLine1 />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="row">
                      <div className="col-md-6">
                        <div>
                          <p className="text-muted">Marketing</p>
                          <h5>84,574</h5>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <SparkLine />
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col> */}

          {/* pie chart */}
          {/* <Row>
            <Col xl={5}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Sales Report</h4>
                  <div className="cleafix">
                    <p className="float-start">
                      <i className="mdi mdi-calendar me-1 text-primary"></i> Jan
                      01 - Jan 31
                    </p>
                    <h5 className="font-18 text-end">BR. 4230</h5>
                  </div>
                  <div id="ct-donut" className="ct-chart wid pt-4">
                    <Salesdonut />
                  </div>
                  <div className="mt-4">
                    <table className="table mb-0">
                      <tbody>
                        <tr>
                          <td>
                            <span className="badge bg-primary">ADV</span>
                          </td>
                          <td>Advanced</td>
                          <td className="text-end">54.5%</td>
                        </tr>
                        <tr>
                          <td>
                            <span className="badge bg-success">BSC</span>
                          </td>
                          <td>Basic</td>
                          <td className="text-end">28.0%</td>
                        </tr>
                        <tr>
                          <td>
                            <span className="badge bg-warning">AIR</span>
                          </td>
                          <td>AirAmbulance</td>
                          <td className="text-end">17.5%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row> */}

          {/* activity list / steps esc */}
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Dashboard)
