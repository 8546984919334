import React, { useEffect, useState } from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Dropdown,
} from "reactstrap"
// import { toast } from "react-toastify"
import { post, del, get, put } from "../../helpers/api_helper"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { GetProviders } from "helpers/backend_helper"
import { LoadingButton } from "pages/Utility/loading"
import validatePhoneNumber from "utils/phone_number_validator"

const CreateDriverModal = ({ isModalOpen, toggleModal, modalType, handleDriverNew }) => {
  const [username, setUserName] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [phone_number, setPhone_number] = useState("")
  const [vehicleId, setvehicleId] = useState("")
  const [providerId, setProvider_id] = useState("")
  const [is_Online, setIs_Online] = useState(false)
  const [is_active, setis_active] = useState(false)
  const [providers, setProviders] = useState([])
  const [vehicles, setVehicles] = useState([])
  const[filteredVehicles, setFilteredVehicles] = useState([])
  const [logedinRoleId, setlogedinRoleId] = useState("")
  const [logedinProvider, setLogedinProvider] = useState("")
  const [showButton, setShowButton] = useState(true);
  async function getRole() {
    const userString = localStorage.getItem('authUser');
    const user = JSON.parse(userString);
    if (user.roleId != '1') {
      setProvider_id(user.provider_id)
    }
    setlogedinRoleId(user.roleId)
    setLogedinProvider(user.provider_id)
  }
  useEffect(() => {
    getRole()
  }, [])



  function allFilled() {
    return (
      // username !== "" &&
      name !== "" &&
      email !== "" &&
      // password !== "" &&
      phone_number !== "" &&
      // vehicleId !== "" &&
      providerId !== "" &&
      is_Online !== "" &&
      is_active !== "" &&
      providers !== "" &&
      vehicles
    )
  }

  async function createManager(event) {
    event.preventDefault()
    if (allFilled() && validatePhoneNumber(phone_number)) {
      try {
        setShowButton(false)
        if (providerId==""){
          providerId = logedinProvider
          console.log(providerId)
        }
        const response = await post("/drivers/", {
          username,
          password,
          providerId,
          name,
          vehicleId,
          email,
          phone_number,
          online: is_Online,
          photo: null,
          idProof: null,
          is_active: is_active,
        })

        // const response = await fetch('https://ride-haling-backend.onrender.com/api/drivers/create', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify({ username, password, providerId, name, vehicleId, email, phone_number, online:is_Online, "photo": null, "idProof": null, is_active}),
        // });
        // console.log( providerId)
        if (response.status >= 200 && response.status < 300) {
          toast.success("Driver created successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          })
          toggleModal(modalType)
          handleDriverNew(response.data)
        } else {
          console.error("Error creating Driver:", response.status)
          toast.error("Error creating Driver. Please try again.")
          setShowButton(true)
        }
      } catch (error) {
        console.error("Network error:", error)
        toast.error("Network error. Please try again later.")
        setShowButton(true)
      }
    } else {
      toast.error("Error fill all filleds or.phone number should be +251 format and 12 degits long.")
      setShowButton(true)
    }
  }
  // console.log( providerId)
  function getProviders() {
    GetProviders()
      .then(data => setProviders(data))
      .catch(error => console.error(error))
  }


  const getVehicles = async () => {
    try {
      const response = await get(`/vehicles`)
      const {data} = response
      // console.log(response)

      const authUser = JSON.parse(localStorage.getItem("authUser"))
      if (authUser.roleId != "1") {
        const _filteredData = data.filter(
          vehicle =>
            vehicle.provider_id == authUser.provider_id &&
            vehicle.provider_id != null &&
            vehicle.provider_id != ""
        )
        setVehicles(_filteredData)
        setFilteredVehicles(_filteredData)
        
      } else {
        setVehicles(data)
        setFilteredVehicles(data)
        console.log(vehicles)
      }
    } catch (error) {
      console.error("Error fetching drivers:", error)
    }
  }


  useEffect(() => {
    getProviders()
    getVehicles()
  }, [])
  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Modal
        isOpen={isModalOpen}
        toggle={() => toggleModal(modalType)}
        size={modalType === "xlarge" ? "xl" : ""}
        scrollable={modalType === "scroll"}
      >
        <ModalHeader toggle={() => toggleModal(modalType)}>
          Driver Information
        </ModalHeader>
        <ModalBody>
          <form>
            {/* <div className="mb-3">
              <label htmlFor="username" className="form-label">
                Username
              </label>
              <input
                required
                type="text"
                className="form-control"
                id="name"
                value={username}
                onChange={ev => setUserName(ev.target.value)}
              />
            </div> */}
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                required
                type="text"
                className="form-control"
                id="name"
                value={name}
                onChange={ev => setName(ev.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                required
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={ev => setEmail(ev.target.value)}
              />
            </div>
            {/* <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                required
                type="email"
                className="form-control"
                id="email"
                value={password}
                onChange={ev => setPassword(ev.target.value)}
              />
            </div> */}
            <div className="mb-3">
              <label htmlFor="phone_number" className="form-label">
                Phone Number *
              </label>
              <input
                required
                type="tel"
                className="form-control"
                id="phone_number"
                value={phone_number}
                onChange={ev => setPhone_number(ev.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="phone_number" className="form-label">
                Vehicle
              </label>
              <select
                className="form-control"
                id="providerId"
                value={vehicleId}
                onChange={e => setvehicleId(e.target.value)}
              >
                <option value="">Select a Vehicle</option>
                {
                  vehicles.length > 0 &&
                  vehicles.map(pro => (
                    <option key={pro?.id} value={pro?.id}>
                      {pro?.car_name}
                    </option>
                  ))}
              </select>
            </div>

            {logedinRoleId == "1" ? (
              <div className="mb-3">
              <label htmlFor="providerId" className="form-label">
                Provider *
              </label>
              <select
                className="form-control"
                id="providerId"
                value={providerId}
                onChange={e => setProvider_id(e.target.value)}
              >
                <option value="">Select a provider</option>
               
                {providers &&
                  providers.map(pro => (
                    <option key={pro?.id} value={pro?.id}>
                      {pro?.name}
                    </option>
                  ))}
              </select>
            </div>) : null}


            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="is_Online"
                checked={is_active}
                onChange={ev => setis_active(ev.target.checked)}
              />
              <label className="form-check-label" htmlFor="is_Active">
                Is Available
              </label>
            </div>
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="is_Online"
                checked={is_Online}
                onChange={ev => setIs_Online(ev.target.checked)}
              />
              <label className="form-check-label" htmlFor="is_Online">
                Is Online
              </label>
            </div>
            <ModalFooter>
              <Button color="secondary" onClick={() => toggleModal(modalType)}>
                Close
              </Button>
              {showButton
                            ?         <Button color="primary" type="submit" onClick={createManager}>
                            Add Driver
                          </Button>:
                           <LoadingButton />}
      
            </ModalFooter>
          </form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default CreateDriverModal
