import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardLink,
} from "reactstrap"
import { useParams, useNavigate, Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { post, del, get, put } from "../../helpers/api_helper"
import {
  GetLoggedInUser,
  GetVehicles,
  GetDriverById,
  UpdateDriver,
  DeleteDriver,
} from "../../helpers/backend_helper"
import { toast } from "react-toastify"

import Dropzone from "react-dropzone"
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import { storage } from "../../helpers/firebase_storage.js" // Adjust the path as needed
import { LoadingPage } from "pages/Utility/loading"

function DriversSlug() {
  const { id } = useParams()
  const navigate = useNavigate()
  const [showLVehicle, setShowLVehicle] = useState(false)
  const [vehicles, setvehicles] = useState([])
  const [vehicle_id, setvehicle_id] = useState("")
  const [driverData, setDriverData] = useState(null)
  const [editedDriverData, setEditedDriverData] = useState(null)

  const changeVehicle = async () => {
    try {
      setShowLVehicle(false)
      const updatedDriverData = { ...driverData, vehicleId: vehicle_id }
      setDriverData(updatedDriverData) // update state locally

      const response = await UpdateDriver(id, updatedDriverData)
      console.log(response)

      if (response.status >= 200 && response.status < 300) {
        toast.success("Vehicle updated successfully")
      } else {
        toast.error("Failed to update vehicle")
      }
    } catch (error) {
      console.error("Error updating vehicle:", error)
      toast.error("An error occurred while updating the vehicle")
    }
  }

  async function getVehicles() {
    // stop using fetch you stupid bastard
    const authUser = GetLoggedInUser()

    if (authUser.provider_id != null) {
      const response = await GetVehicles({
        params: { provider_id: authUser.provider_id },
      })
      setvehicles(response.data)
    }
    //   else{
    //     const response = await GetVehicles( {params: { provider_id: authUser.provider_id}})
    //     setvehicles(response.data)

    // }
  }

  useEffect(() => {
    getVehicles()
  }, [])

  useEffect(() => {
    // Fetch the driver data
    const fetchDriverData = async () => {
      try {
        const data = await GetDriverById(id)
        // const data = await response
        setDriverData(data)
        setEditedDriverData(data)
        console.log("////////////////////")
        console.log(data)
      } catch (error) {
        console.error("Error fetching driver data:", error)
        toast.error("Error fetching driver data")
      }
    }
    fetchDriverData()
    // getVehicles()
  }, [id])

  const handlePhotoAcceptedFiles = async acceptedFiles => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0]
      const storageRef = ref(storage, `images/${file.name}`)

      try {
        await uploadBytes(storageRef, file)

        const fileURL = await getDownloadURL(storageRef)

        setEditedDriverData(prevData => ({
          ...prevData,
          photo: fileURL,
        }))
      } catch (error) {
        toast.error("Error uploading file")
      }
    }
  }
  const handleIDProofAcceptedFiles = async acceptedFiles => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0]
      const storageRef = ref(storage, `images/${file.name}`)

      try {
        await uploadBytes(storageRef, file)

        const fileURL = await getDownloadURL(storageRef)

        setEditedDriverData(prevData => ({
          ...prevData,
          idProof: fileURL,
        }))
      } catch (error) {
        toast.error("Error uploading file")
      }
    }
  }

  const [isEditing, setIsEditing] = useState(false)

  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleSave = async () => {
    try {
      // Log the save process start
      console.log("///////////////////////////////// save")
      console.log(editedDriverData)

      // Update local state
      setDriverData(editedDriverData)
      setIsEditing(false)

      // Send a POST request to update driver data
      const response = await UpdateDriver(id, editedDriverData)
      console.log(response)
      // Check response status
      if (response.status >= 200 && response.status < 300) {
        // Show success message
        toast.success("Updated successfully")
      } else {
        // Show failure message
        toast.error("Update failed")
      }

      // Log the server response
      console.log(response)
    } catch (error) {
      // Handle any errors
      toast.error("An error occurred: " + error.message)
    }
  }

  const handleDelete = async () => {
    try {
      console.log("///////////////////////////////// delete")
      // setEditedDriverData(driverData)
      // setEditProviderDetail(false)
      const response = await DeleteDriver(id, {})

      if (response.status >= 200 && response.status < 300) {
        console.log("Driver deleted successfully")
        toast.success("deleted")
        setTimeout(() => {
          navigate("/drivers")
        }, 1000)
      } else {
        throw new Error("Error deleting driver: " + response.statusText)
      }
    } catch (error) {
      toast.error(error)
    }
  }
  const handleCancel = () => {
    setEditedDriverData(driverData)
    setIsEditing(false)
  }

  const handleVehicleClick = () => {
    navigate(`/vehicles/${driverData.vehicle.id}`)
  }
  if (!driverData) {
    return <LoadingPage />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="align-items-center row justify-content-start">
            <Col>
              <Breadcrumbs maintitle="hearts" title="Driver" />
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col>
              <h2>Driver Details</h2>
              <Form>
                <Row>
                  <Col>
                    {/* <FormGroup>
                      <Label for="photo">Photo</Label>
                      {driverData.idProof ? (
                        <img
                          src={driverData.photo}
                          alt="ID Proof"
                          style={{ maxWidth: "200px", maxHeight: "200px" }}
                          onError={e => {
                            e.target.src = user3
                          }}
                        />
                      ) : (
                        <img
                          src={user3}
                          alt="User Icon"
                          style={{ maxWidth: "200px", maxHeight: "200px" }}
                        />
                      )}
                    </FormGroup> */}

                    <FormGroup>
                      <Label for="photo">Photo</Label>
                      {isEditing ? (
                        <Dropzone onDrop={handlePhotoAcceptedFiles}>
                          {({ getRootProps, getInputProps }) => (
                            <div
                              {...getRootProps()}
                              style={{
                                backgroundImage: `url(${
                                  editedDriverData?.photo ||
                                  driverData.photo ||
                                  user3
                                })`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                height: "100px",
                                width: "100px",
                                borderRadius: "50%",
                                border: "2px solid #ccc",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <input {...getInputProps()} />
                              {isEditing && (
                                <div
                                  style={{
                                    position: "relative",
                                    top: "50px",
                                    left: "50px",
                                    background: "rgba(255, 255, 255, 0.7)",
                                    borderRadius: "50%",
                                    padding: "5px",
                                  }}
                                >
                                  <i className="mdi mdi-cloud-upload display-6 text-muted"></i>
                                </div>
                              )}
                            </div>
                          )}
                        </Dropzone>
                      ) : (
                        <div
                          style={{
                            backgroundImage: `url(${
                              editedDriverData?.photo ||
                              driverData.photo ||
                              user3
                            })`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            height: "100px",
                            width: "100px",
                            borderRadius: "50%",
                            border: "2px solid #ccc",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        />
                      )}
                    </FormGroup>

                    <FormGroup>
                      <Label for="idproof">ID Proof</Label>
                      {isEditing ? (
                        <Dropzone onDrop={handleIDProofAcceptedFiles}>
                          {({ getRootProps, getInputProps }) => (
                            <div
                              {...getRootProps()}
                              style={{
                                backgroundImage: `url(${
                                  editedDriverData?.idProof ||
                                  driverData.idProof ||
                                  user3
                                })`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                height: "100px",
                                width: "100px",
                                borderRadius: "50%",
                                border: "2px solid #ccc",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <input {...getInputProps()} />
                              {isEditing && (
                                <div
                                  style={{
                                    position: "relative",
                                    top: "50px",
                                    left: "50px",
                                    background: "rgba(255, 255, 255, 0.7)",
                                    borderRadius: "50%",
                                    padding: "5px",
                                  }}
                                >
                                  <i className="mdi mdi-cloud-upload display-6 text-muted"></i>
                                </div>
                              )}
                            </div>
                          )}
                        </Dropzone>
                      ) : (
                        <div
                          style={{
                            backgroundImage: `url(${
                              editedDriverData?.idProof ||
                              driverData.idProof ||
                              user3
                            })`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            height: "100px",
                            width: "100px",
                            borderRadius: "50%",
                            border: "2px solid #ccc",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        />
                      )}
                    </FormGroup>

                    {/* <FormGroup>
                      <Label for="idProof">ID Proof</Label>
                      {driverData.idProof ? (
                        <img
                          src={driverData.idProof}
                          alt="ID Proof"
                          style={{ maxWidth: "200px", maxHeight: "200px" }}
                          onError={e => {
                            e.target.src = user2
                          }}
                        />
                      ) : (
                        <img
                          src={user2}
                          alt="User Icon"
                          style={{ maxWidth: "200px", maxHeight: "200px" }}
                        />
                      )} */}
                    {/* </FormGroup> */}
                  </Col>

                  <Col>
                    <FormGroup>
                      <Label for="phone_number">Phone Number:</Label>
                      <Input
                        type="tel"
                        id="phone_number"
                        value={editedDriverData?.phone_number || ""}
                        onChange={e =>
                          setEditedDriverData({
                            ...editedDriverData,
                            phone_number: e.target.value,
                          })
                        }
                        disabled={!isEditing}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="username">username:</Label>
                      <Input
                        type="text"
                        id="username"
                        value={editedDriverData?.username || ""}
                        onChange={e =>
                          setEditedDriverData({
                            ...editedDriverData,
                            username: e.target.value,
                          })
                        }
                        disabled={!isEditing}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="name">Name:</Label>
                      <Input
                        type="text"
                        id="name"
                        value={editedDriverData?.name || ""}
                        onChange={e =>
                          setEditedDriverData({
                            ...editedDriverData,
                            name: e.target.value,
                          })
                        }
                        disabled={!isEditing}
                      />
                    </FormGroup>

                    <FormGroup check className="form-check form-switch mb-3">
                      <Label
                        check
                        className="form-check-label"
                        htmlFor="online"
                      >
                        Online
                      </Label>
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="online"
                        checked={editedDriverData?.online || false}
                        onClick={e => {
                          console.log("clicked")
                          setEditedDriverData({
                            ...editedDriverData,
                            online: e.target.checked,
                          })
                        }}
                        onChange={e => {
                          console.log("changed")
                          setEditedDriverData({
                            ...editedDriverData,
                            online: e.target.checked,
                          })
                        }}
                        disabled={!isEditing}
                      />
                    </FormGroup>
                    <FormGroup check className="form-check form-switch mb-3">
                      <Label
                        check
                        className="form-check-label"
                        htmlFor="is_active"
                      >
                        Is Active
                      </Label>
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="is_active"
                        checked={editedDriverData?.is_active || false}
                        onClick={e => {
                          console.log("clicked")
                          setEditedDriverData({
                            ...editedDriverData,
                            is_active: e.target.checked,
                          })
                        }}
                        onChange={e => {
                          console.log("changed")
                          setEditedDriverData({
                            ...editedDriverData,
                            is_active: e.target.checked,
                          })
                        }}
                        disabled={!isEditing}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {/* Other form fields... */}
                {isEditing ? (
                  <Row>
                    <Col sm="1">
                      <Button color="primary" onClick={handleSave}>
                        Save
                      </Button>
                    </Col>
                    <Col sm="1">
                      <Button color="danger" onClick={handleDelete}>
                        Delete
                      </Button>
                    </Col>
                    <Col sm="1">
                      <Button color="warning" onClick={handleCancel}>
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col sm="1">
                      <Button
                        color="primary"
                        onClick={() => setIsEditing(true)}
                      >
                        Edit
                      </Button>
                    </Col>
                  </Row>
                )}
              </Form>
            </Col>
          </Row>
          <br />
          {/* vehicle /  */}
          <Row>
            <Col md={4}>
              <Card>
                <CardBody>
                  <CardTitle tag="h5">Vehicle Details</CardTitle>
                  {driverData.vehicle && (
                    <>
                      <CardText>
                        <div>Car Name: {driverData.vehicle.car_name}</div>
                        <div>
                          Plate Number: {driverData.vehicle.plate_number}
                        </div>
                        <div>Vehicle Type: {driverData.vehicle.type}</div>
                      </CardText>
                      {/* <CardLink href="#" onClick={handleVehicleClick}>
                        View Vehicle Details
                        </CardLink> */}
                    </>
                  )}
                  {showLVehicle ? (
                    <div className="mb-3">
                      <label htmlFor="vehicle_id" className="form-label">
                        Vehicle List
                      </label>
                      <select
                        className="form-control"
                        id="vehicle_id"
                        value={vehicle_id}
                        onChange={e => setvehicle_id(e.target.value)}
                      >
                        <option value="">Select a Vehicle</option>
                        {vehicles.length ? (
                          Array.isArray(vehicles) &&
                          vehicles.length > 0 &&
                          vehicles.map(vehicle => (
                            <option key={vehicle.id} value={vehicle.id}>
                              {vehicle.car_name}
                            </option>
                          ))
                        ) : (
                          <strong>No available Vehicles</strong>
                        )}
                      </select>
                      <br />
                      <Row>
                        <Col>
                          <Button
                            className="mb-3"
                            onClick={changeVehicle}
                            color="success"
                          >
                            Save
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            className="mb-3"
                            onClick={() => setShowLVehicle(false)}
                            color="warning"
                          >
                            cancel
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <Button
                      onClick={() => setShowLVehicle(true)}
                      color="primary"
                    >
                      Change Vehicle
                    </Button>
                  )}
                </CardBody>
              </Card>
            </Col>

            <Col md={4}>
              <Card>
                <CardBody>
                  <CardTitle tag="h5">Location</CardTitle>
                  <CardText>
                    Latitude: {driverData.driver_location.latitude}
                    <br />
                    Longitude: {driverData.driver_location.longitude}
                    <br />
                    Accuracy: {driverData.driver_location.accuracy}
                  </CardText>
                </CardBody>
              </Card>
            </Col>

            <Col md={4}>
              <Card>
                <CardBody>
                  <CardTitle tag="h5">Provider</CardTitle>
                  <CardText>
                    provider: {driverData?.Provider?.name}
                    <br />
                    <Link to={`/providers/${driverData?.Provider?.id}`}>
                      view provider details
                    </Link>
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DriversSlug
