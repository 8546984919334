import React, { useEffect, useState } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"
// import { toast } from "react-toastify"
import { post, del, get, put } from "../../helpers/api_helper"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useParams } from "react-router-dom"

const CreateAmbulanceTypeModal = ({
  isModalOpen,
  toggleModal,
  modalType,
  handleAmbulanceTypeNew,
}) => {
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const id = authUser.provider_id

  const [name, setName] = useState("")
  var [farePerKm, setfarePerKm] = useState(0)
  var [fixedPrice, setfixedPrice] = useState(0)
  const [is_active, setIs_active] = useState(true)

  async function AddAmbulanceType() {
    fixedPrice = Number(fixedPrice)
    farePerKm = Number(farePerKm)
    try {
      const response = await post("/ambulance_types/create", {
        name,
        farePerKm,
        fixedPrice,
        is_active,
        provider_id: id,
      })

      if (response) {
        toast.success("ambulance_type created successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
        toggleModal()
        handleAmbulanceTypeNew(response.data)
        // console.log("ambulance_type created successfully!", response)
      } else {
        console.error("Error creating ambulance_type:", response.status)
        toast.error("Error creating ambulance_type. Please try again.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      }
    } catch (error) {
      console.error("Network error:", error)
      toast.error("Network error. Please try again later.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      })
    }
  }
  // if (toast){
  //   return(<div>
  //     Toasted
  //   </div>)
  // }
  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Modal
        isOpen={isModalOpen}
        toggle={() => toggleModal(modalType)}
        size={modalType === "xlarge" ? "xl" : ""}
        scrollable={modalType === "scroll"}
      >
        <ModalHeader toggle={() => toggleModal(modalType)}>
          Ambulance Type Information
        </ModalHeader>
        <ModalBody>
          <form>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Type
              </label>
              <select
                required
                className="form-control"
                id="name"
                value={name}
                onChange={ev => setName(ev.target.value)}
              >
                <option value=""></option>
                <option value={"BASIC"}>BASIC</option>
                <option value={"ADVANCED"}>ADVANCED</option>
              </select>
            </div>

            <div className="mb-3">
              <label htmlFor="farePerKm" className="form-label">
                Fare Per kilometer
              </label>
              <input
                required
                type="number"
                className="form-control"
                id="farePerKm"
                value={farePerKm}
                onChange={ev => setfarePerKm(ev.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="fixedPrice" className="form-label">
                Fixed Price
              </label>
              <input
                required
                type="number"
                className="form-control"
                id="fixedPrice"
                value={fixedPrice}
                onChange={ev => setfixedPrice(ev.target.value)}
              />
            </div>
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="is_active"
                checked={is_active}
                onChange={ev => setIs_active(ev.target.checked)}
              />
              <label className="form-check-label" htmlFor="is_active">
                Is Active
              </label>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => toggleModal(modalType)}>
            Close
          </Button>
          <Button color="primary" type="submit" onClick={AddAmbulanceType}>
            Add Ambulance Type
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default CreateAmbulanceTypeModal
