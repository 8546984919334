//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"
// export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
// export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const POST_FAKE_JWT_LOGIN = "/auth/login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"



export const LOGIN = "/auth/login"
export const  providers = "/providers"
export const GET_PROVIDER_BY_ID = (id) => `/providers/${id}`
export const Drivers = "/drivers"
export const GET_DRIVER_BY_ID = (id) => `/drivers/${id}`
export const Managers = "/managers"
export const GET_MANAGER_BY_ID = (id) => `/managers/${id}`
export const Bookings = "/bookings"
export const GET_CLIENT_BY_ID = (id) => `/clients/${id}`
export const Clients = "/clients"
export const GET_BOOKING_BY_ID = (id) => `/bookings/${id}`
export const Vehicles = "/vehicles"
export const GET_VEHICLE_BY_ID = (id) => `/vehicles/${id}`
export const Reviews = "/reviews"
export const GET_REVIEW_BY_ID = (id) => `/reviews/${id}`

export const Banners = '/banners'
export const GET_BANNER_BY_ID = (id) => `/banners/${id}`