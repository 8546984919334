import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap"
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams,
  useLocation,
  Link,
  useNavigate,
} from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import user3 from "../../assets/images/users/user-3.jpg" // replace with your desired image
import defaultVehicle from "../../assets/images/driver_location.png"
import { toast } from "react-toastify"
import { post, del, get, put } from "../../helpers/api_helper"
import Dropzone from "react-dropzone"
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import { storage } from "../../helpers/firebase_storage.js" // Adjust the path as needed
import { LoadingPage } from "pages/Utility/loading"

function VehicleSlug() {
  const [editingVehicle, setEditingVehicle] = useState(false)
  const [vehicle, setVehicle] = useState()

  const navigate = useNavigate()
  const { id } = useParams()
  const location = useLocation()

  const handlePhotoAcceptedFiles = async acceptedFiles => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0]
      const storageRef = ref(storage, `images/${file.name}`)

      try {
        await uploadBytes(storageRef, file)

        const fileURL = await getDownloadURL(storageRef)

        setVehicle(prevData => ({
          ...prevData,
          photo: fileURL,
        }))
      } catch (error) {
        toast.error("Error uploading file")
      }
    }
  }

  const handleEdit = () => {
    setEditingVehicle(true)
  }

  const handleSave = async () => {
    // Save the updated vehicle data
    setEditingVehicle(false)
    try {
      await put(`/vehicles/${id}`, vehicle)
      toast.success("success")
    } catch (error) {
      toast.error("error")
    }
  }
  const handleDelete = async () => {
    // Delete the vehicle data
    // Add your implementation here
    setEditingVehicle(false)
    const res = await del(`/vehicles/${id}`, vehicle)
    if (res.status == 200) {
      toast.success("deleted")
      setTimeout(() => {
        navigate("/vehicles")
      }, 1000)
    } else {
      throw Error("error deleting")
    }
  }
  useEffect(() => {
    // Fetch the manager data from the server or other data source
    const fetchVehicleData = async () => {
      try {
        const response = await get(`/vehicles/${id}`)
        const data = await response
        console.log(data)
        setVehicle(data)
      } catch (error) {
        toast.error("Error fetching vehicle data")
        console.error("Error fetching vehicle data:", error)
      }
    }
    fetchVehicleData()
  }, [id])

  if (!vehicle) {
    return <LoadingPage />
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="7">
              <Breadcrumbs maintitle="hearts" title="Vehicle" />
            </Col>
            <Col md="5" className="align-self-end" />
          </Row>
          {/* Render Card */}
          <div className="card">
            <div className="card-body">
              <Row>
                <Col xl={8} md={8}>
                  <div className="d-flex align-items-center">
                    <div>
                      {editingVehicle ? (
                        <Form>
                          <FormGroup>
                            <Label for="car_name">Car Name</Label>
                            <Input
                              type="text"
                              name="car_name"
                              id="car_name"
                              value={vehicle.car_name}
                              onChange={e =>
                                setVehicle({
                                  ...vehicle,
                                  car_name: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="plate_number">Plate Number</Label>
                            <Input
                              type="text"
                              name="plate_number"
                              id="plate_number"
                              value={vehicle.plate_number}
                              onChange={e =>
                                setVehicle({
                                  ...vehicle,
                                  plate_number: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="type">Type</Label>
                            <Input
                              type="select"
                              name="type"
                              id="type"
                              value={vehicle.type}
                              onChange={e =>
                                setVehicle({ ...vehicle, type: e.target.value })
                              }
                            >
                              <option value="BASIC">BASIC</option>
                              <option value="ADVANCED">ADVANCED</option>
                            </Input>
                          </FormGroup>
                          <FormGroup
                            check
                            className="form-check form-switch mb-3"
                          >
                            <Label
                              check
                              className="form-check-label"
                              htmlFor="availability"
                            >
                              Is Active
                            </Label>
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              id="availability"
                              checked={
                                vehicle?.ambulance_type?.is_active || false
                              }
                              onClick={e => {
                                console.log("clicked")
                                setVehicle({
                                  ...vehicle,
                                  ambulance_type: {
                                    ...vehicle.ambulance_type,
                                    is_active: e.target.checked,
                                  },
                                })
                              }}
                              onChange={e => {
                                console.log("changed")
                                setVehicle({
                                  ...vehicle,
                                  ambulance_type: {
                                    ...vehicle.ambulance_type,
                                    is_active: e.target.checked,
                                  },
                                })
                              }}
                              disabled={!editingVehicle}
                            />
                          </FormGroup>
                        </Form>
                      ) : (
                        <div>
                          <h5 className="card-title">{vehicle.car_name}</h5>
                          <span className="card-text">
                            Plate Number: {vehicle.plate_number}
                            <br />
                            Type: {vehicle.type}
                            <br />
                            <br />
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <Row>
                    <Col>
                      {vehicle?.Driver ? (
                        <Link to={`/drivers/${vehicle?.Driver?.id}`}>
                          <div className="d-flex align-items-center mt-3">
                            <img
                              src={vehicle?.Driver?.photo || user3}
                              alt={vehicle?.Driver?.username}
                              className="avatar-sm rounded-circle me-2"
                              onError={e => {
                                e.target.src = user3
                              }}
                            />
                            <div>
                              <h6 className="mb-0">
                                {vehicle?.Driver?.username}
                              </h6>
                              <small className="text-muted">Driver</small>
                            </div>
                          </div>
                        </Link>
                      ) : (
                        <div>
                          <br />
                          <strong>no driver assigned</strong>
                        </div>
                      )}
                    </Col>
                    <Col>
                      {/*  */}
                      Is Active :{" "}
                      {vehicle?.ambulance_type?.is_active ? "True" : "False"}
                      <p>fixedPrice : {vehicle?.ambulance_type?.fixedPrice}</p>
                      <p>farePerKm : {vehicle?.ambulance_type?.farePerKm}</p>
                    </Col>
                  </Row>
                </Col>

                <Col xl={4} md={4}>
                  {editingVehicle ? (
                    <Dropzone onDrop={handlePhotoAcceptedFiles}>
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps()}
                          style={{
                            backgroundImage: `url(${
                              vehicle?.photo || defaultVehicle
                            })`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            height: "50px",
                            width: "50px",
                            borderRadius: "50%",
                            border: "2px solid #ccc",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <input {...getInputProps()} />
                          {editingVehicle && (
                            <div
                              style={{
                                position: "relative",
                                top: "20px",
                                left: "25px",
                                background: "rgba(255, 255, 255, 0.7)",
                                borderRadius: "50%",
                                padding: "0px",
                              }}
                            >
                              <i className="mdi mdi-cloud-upload display-6 text-muted"></i>
                            </div>
                          )}
                        </div>
                      )}
                    </Dropzone>
                  ) : (
                    <Row>
                      <div className="d-flex align-items-center mt-3">
                        <img
                          src={vehicle.photo || defaultVehicle}
                          alt={vehicle.car_name}
                          className="rounded me-1"
                          style={{ maxHeight: "150px" }}
                          onError={e => {
                            e.target.src = defaultVehicle
                          }}
                        />
                      </div>
                    </Row>
                  )}
                  <br />
                  <Row>
                    <div>
                      {editingVehicle ? (
                        <>
                          <Button
                            color="primary"
                            className="btn-sm me-2"
                            onClick={handleSave}
                          >
                            Save
                          </Button>

                          <Button
                            color="danger"
                            className="btn-sm me-2"
                            onClick={handleDelete}
                          >
                            Delete
                          </Button>
                          <Button
                            color="warning"
                            className="btn-sm"
                            onClick={() => setEditingVehicle(false)}
                          >
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <Button
                          color="primary"
                          className="btn-sm"
                          onClick={handleEdit}
                        >
                          Edit
                        </Button>
                      )}
                    </div>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default VehicleSlug
