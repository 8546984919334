import React, { useState, useEffect } from "react"
import {
  Container,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import Dropzone from "react-dropzone"
import { post, del, get, put } from "../../helpers/api_helper"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useParams, useNavigate } from "react-router-dom"
import user3 from "../../assets/images/users/user-3.jpg"
import { toast } from "react-toastify"
import CreateAmbulanceTypeModal from "./CreateAmbulanceTypeModal"

import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import { storage } from "../../helpers/firebase_storage.js" // Adjust the path as needed
import { LoadingPage } from "pages/Utility/loading"

const ProvidersSlug = props => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [providerData, setProviderData] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [editProviderDetail, setEditProviderDetail] = useState(false)
  const [editedProviderData, setEditedProviderData] = useState(null)
  const [isAmbulanceTypeEditing, setIsAmbulanceTypeEditing] = useState(false)
  const [selectedAmbulanceType, setSelectedAmbulanceType] = useState(null)

  useEffect(() => {
    const fetchManagerData = async () => {
      try {
        const data = await get(`/providers/${id}`)
        setProviderData(data)
        setEditedProviderData(data)
        console.log("==============================> useeffect data")
        console.log(data)
      } catch (error) {
        console.error("Error fetching providers data:", error)
        toast.error("Error fetching providers data")
      }
    }
    fetchManagerData()
  }, [id])

  const handleAcceptedFiles = async acceptedFiles => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0]
      const storageRef = ref(storage, `images/${file.name}`)

      try {
        await uploadBytes(storageRef, file)

        const fileURL = await getDownloadURL(storageRef)

        setEditedProviderData(prevData => ({
          ...prevData,
          photo: fileURL,
        }))
      } catch (error) {
        toast.error("Error uploading file")
      }
    }
  }

  const handleEdit = () => setIsEditing(true)
  const handleSave = async () => {
    try {
      setEditProviderDetail(false)
      await put(`/providers/${id}`, editedProviderData)
      setProviderData(editedProviderData)
      toast.success("updated")
    } catch (error) {
      toast.error(error)
    }
  }

  const handleDelete = async () => {
    try {
      setEditedProviderData(providerData)
      setEditProviderDetail(false)
      const res = await put(`/providers/${id}`, {})
      if (res.status == 200) {
        toast.success("deleted")
        setTimeout(() => {
          navigate("/providers")
        }, 1000)
      } else {
        throw Error("error deleting")
      }
    } catch (error) {
      toast.error(error)
    }
  }
  const handleCancel = () => {
    setEditedProviderData(providerData)
    setEditProviderDetail(false)
  }
  const handleAmbulanceTypeEdit = ambulanceType => {
    setSelectedAmbulanceType(ambulanceType)
    setIsAmbulanceTypeEditing(true)
  }

  // const handleAmbulanceTypeSave = async ambulanceType => {
  //   try {
  //     // Update the ambulance type in the providerData.AmbulanceTypes array
  //     const updatedAmbulanceTypes = providerData.AmbulanceTypes.map(type => {
  //       if (type.id === ambulanceType.id) {
  //         return {
  //           ...type,
  //           farePerKm: selectedAmbulanceType.farePerKm,
  //           is_active: selectedAmbulanceType.is_active,
  //           fixedPrice: selectedAmbulanceType.fixedPrice,
  //         }
  //       }
  //       return type
  //     })

  //     // Update the providerData with the updated ambulance types
  //     setProviderData({
  //       ...providerData,
  //       AmbulanceTypes: updatedAmbulanceTypes,
  //     })

  //     // Reset the editing state
  //     setIsAmbulanceTypeEditing(false)
  //     setSelectedAmbulanceType(null)

  //     // Save the updated provider data to the server
  //     await post(`/providers/update/${id}`, providerData)
  //     toast.success("Ambulance type updated")
  //   } catch (error) {
  //     toast.error(error.message)
  //   }
  // }

  const handleAmbulanceTypeNew = ambulanceType => {
    setProviderData(prevData => {
      const updatedAmbulanceTypes = [...prevData.AmbulanceTypes, ambulanceType]
      return {
        ...prevData,
        AmbulanceTypes: updatedAmbulanceTypes,
      }
    })
    console.log("===========> handleAmbulanceTypeNew")
    console.log(providerData)
  }

  const handleAmbulanceTypeSave = async ambulanceType => {
    const r = await put(`/ambulance_types/${ambulanceType.id}`, ambulanceType)

    // setProviderData(providerData)
    setSelectedAmbulanceType(ambulanceType)
    setIsAmbulanceTypeEditing(false)
    // editedProviderData.ambulanceType = ambulanceType
    // setProviderData(editedProviderData)
    setProviderData({
      ...providerData,
      AmbulanceTypes: providerData.AmbulanceTypes.map(type => {
        if (type.id === ambulanceType.id) {
          return ambulanceType
        }
        return type
      }),
    })

    // console.log("===========> handleAmbulanceTypeSave")
    // console.log(r)
    toast.success("Ambulance type updated")
  }

  const handleAmbulanceTypeDelete = async ambulanceType => {
    await put(`/ambulance_types/${ambulanceType.id}`, {})
    setProviderData({
      ...providerData,
      AmbulanceTypes: providerData.AmbulanceTypes.filter(
        type => type.id !== ambulanceType.id
      ),
    })
    setSelectedAmbulanceType(ambulanceType)
    setIsAmbulanceTypeEditing(true)
    toast.success("Ambulance type deleted")
  }
  const handleAmbulanceTypeCancel = ambulanceType => {
    setIsAmbulanceTypeEditing(false)
    setSelectedAmbulanceType(ambulanceType)
  }
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalType, setModalType] = useState("")

  const toggleModal = type => {
    setModalType(type)
    setIsModalOpen(!isModalOpen)
  }

  if (!providerData) return <LoadingPage />

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="7">
              <Breadcrumbs
                maintitle="hearts"
                title="Provider"
                // breadcrumbItem={id}
              />
            </Col>
            <Col md="5" className="align-self-end" />
          </Row>

          <div>
            <h2>Provider Details</h2>
            <Form>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="image">Profile image</Label>
                    {editProviderDetail ? (
                      <Dropzone onDrop={handleAcceptedFiles}>
                        {({ getRootProps, getInputProps }) => (
                          <div
                            {...getRootProps()}
                            style={{
                              backgroundImage: `url(${
                                editedProviderData?.photo ||
                                providerData.photo ||
                                user3
                              })`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              height: "100px",
                              width: "100px",
                              borderRadius: "50%",
                              border: "2px solid #ccc",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <input {...getInputProps()} />
                            {editProviderDetail && (
                              <div
                                style={{
                                  position: "relative",
                                  top: "50px",
                                  left: "50px",
                                  background: "rgba(255, 255, 255, 0.7)",
                                  borderRadius: "50%",
                                  padding: "5px",
                                }}
                              >
                                <i className="mdi mdi-cloud-upload display-6 text-muted"></i>
                              </div>
                            )}
                          </div>
                        )}
                      </Dropzone>
                    ) : (
                      <div
                        style={{
                          backgroundImage: `url(${
                            editedProviderData?.photo ||
                            providerData.photo ||
                            user3
                          })`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          height: "100px",
                          width: "100px",
                          borderRadius: "50%",
                          border: "2px solid #ccc",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      />
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="name">Name:</Label>
                    <Input
                      type="text"
                      id="name"
                      value={editedProviderData?.name || ""}
                      onChange={e =>
                        setEditedProviderData({
                          ...editedProviderData,
                          name: e.target.value,
                        })
                      }
                      disabled={!editProviderDetail}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="email">Email:</Label>
                    <Input
                      type="email"
                      id="email"
                      value={editedProviderData?.email || ""}
                      onChange={e =>
                        setEditedProviderData({
                          ...editedProviderData,
                          email: e.target.value,
                        })
                      }
                      disabled={!editProviderDetail}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="phone_number">Phone Number:</Label>
                    <Input
                      type="tel"
                      id="phone_number"
                      value={editedProviderData?.phone_number || ""}
                      onChange={e =>
                        setEditedProviderData({
                          ...editedProviderData,
                          phone_number: e.target.value,
                        })
                      }
                      disabled={!editProviderDetail}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup check className="form-check form-switch mb-3">
                    <Label
                      check
                      className="form-check-label"
                      htmlFor="is_active"
                    >
                      Is Active
                    </Label>
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      id="is_active"
                      checked={editedProviderData?.is_active || false}
                      onClick={e => {
                        console.log("clicked")
                        setEditedProviderData({
                          ...editedProviderData,
                          is_active: e.target.checked,
                        })
                      }}
                      onChange={e => {
                        console.log("changed")
                        setEditedProviderData({
                          ...editedProviderData,
                          is_active: e.target.checked,
                        })
                      }}
                      disabled={!editProviderDetail}
                    />
                  </FormGroup>
                </Col>
              </Row>

              {editProviderDetail ? (
                <Row>
                  <Col sm="1">
                    <Button color="primary" onClick={handleSave}>
                      Save
                    </Button>
                  </Col>
                  <Col sm="1">
                    <Button color="danger" onClick={handleDelete}>
                      Delete
                    </Button>
                  </Col>
                  <Col sm="1">
                    <Button color="warning" onClick={handleCancel}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col sm="1">
                    <Button
                      color="primary"
                      onClick={() => setEditProviderDetail(true)}
                    >
                      Edit
                    </Button>
                  </Col>
                </Row>
              )}
            </Form>
          </div>

          <div>
            <br />
            <h2>Ambulance Types</h2>
            {providerData.AmbulanceTypes.map(ambulanceType => (
              <Card key={ambulanceType.id}>
                <CardBody>
                  <h3>{ambulanceType.name}</h3>
                  <Form>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label for="farePerKm">Fare Per KM:</Label>
                          <Input
                            type="number"
                            id="farePerKm"
                            value={
                              selectedAmbulanceType?.id === ambulanceType.id
                                ? selectedAmbulanceType.farePerKm
                                : ambulanceType.farePerKm
                            }
                            onChange={e =>
                              setSelectedAmbulanceType({
                                ...selectedAmbulanceType,
                                farePerKm: parseFloat(e.target.value),
                              })
                            }
                            disabled={
                              !(
                                isAmbulanceTypeEditing &&
                                selectedAmbulanceType?.id === ambulanceType.id
                              )
                            }
                          />
                        </FormGroup>

                        <FormGroup
                          check
                          className="form-check form-switch mb-3"
                        >
                          <Label
                            check
                            className="form-check-label"
                            htmlFor="is_active"
                          >
                            Is Active
                          </Label>
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            id="is_active"
                            checked={
                              selectedAmbulanceType?.id === ambulanceType.id
                                ? selectedAmbulanceType.is_active
                                : ambulanceType.is_active
                            }
                            onClick={e => {
                              // console.log("clicked")
                              if (
                                selectedAmbulanceType?.id === ambulanceType.id
                              ) {
                                setSelectedAmbulanceType({
                                  ...selectedAmbulanceType,
                                  is_active: e.target.checked,
                                })
                              }
                            }}
                            onChange={e => {
                              // console.log("changed")
                              if (
                                selectedAmbulanceType?.id === ambulanceType.id
                              ) {
                                setSelectedAmbulanceType({
                                  ...selectedAmbulanceType,
                                  is_active: e.target.checked,
                                })
                              }
                            }}
                            disabled={
                              !(
                                isAmbulanceTypeEditing &&
                                selectedAmbulanceType?.id === ambulanceType.id
                              )
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label for="fixedPrice">Fixed Price:</Label>
                          <Input
                            type="number"
                            id="fixedPrice"
                            value={
                              selectedAmbulanceType?.id === ambulanceType.id
                                ? selectedAmbulanceType.fixedPrice
                                : ambulanceType.fixedPrice
                            }
                            onChange={e =>
                              setSelectedAmbulanceType({
                                ...selectedAmbulanceType,
                                fixedPrice: parseFloat(e.target.value),
                              })
                            }
                            disabled={
                              !(
                                isAmbulanceTypeEditing &&
                                selectedAmbulanceType?.id === ambulanceType.id
                              )
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    {isAmbulanceTypeEditing &&
                    selectedAmbulanceType?.id === ambulanceType.id ? (
                      <Row>
                        <Col sm="1">
                          <Button
                            color="primary"
                            onClick={() =>
                              handleAmbulanceTypeSave(selectedAmbulanceType)
                            }
                          >
                            Save
                          </Button>
                        </Col>
                        <Col sm="1">
                          <Button
                            color="danger"
                            onClick={() =>
                              handleAmbulanceTypeDelete(ambulanceType)
                            }
                          >
                            Delete
                          </Button>
                        </Col>
                        <Col sm="1">
                          <Button
                            color="secondary"
                            onClick={() =>
                              handleAmbulanceTypeCancel(ambulanceType)
                            }
                          >
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col sm="1">
                          <Button
                            color="primary"
                            onClick={() =>
                              handleAmbulanceTypeEdit(ambulanceType)
                            }
                          >
                            Edit
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </Form>
                </CardBody>
              </Card>
            ))}

            <div className="my-4 text-center">
              <Button
                color="primary"
                onClick={() => toggleModal("scroll")}
                className="m-0"
              >
                Create New Ambulance Type
              </Button>
            </div>
            <CreateAmbulanceTypeModal
              isModalOpen={isModalOpen}
              toggleModal={toggleModal}
              modalType={modalType}
              handleAmbulanceTypeNew={handleAmbulanceTypeNew}
            />
            {/* <Button
              color="primary"
              onClick={() => CreateAmbulanceType({id:id})}
            >
              Create New Ambulance Type
            </Button> */}
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProvidersSlug

// const CreateAmbulanceType = ({ id }) => {
//   const [modal, setModal] = useState(false)
//   const [farePerKm, setFarePerKm] = useState("")
//   const [fixedPrice, setFixedPrice] = useState("")
//   const [name, setName] = useState("ADVANCED")
//   const [dropdownOpen, setDropdownOpen] = useState(false)

//   const toggleModal = () => setModal(!modal)
//   const toggleDropdown = () => setDropdownOpen(!dropdownOpen)

//   const createAmbulanceType = () => {
//     const data = {
//       farePerKm: parseFloat(farePerKm),
//       fixedPrice: parseFloat(fixedPrice),
//       name,
//     }
//     post(`ambulancetypes/create`, { provider_id: id, ...data })
//     toggleModal()
//   }

//   return (
//     <div>
//       <Button color="primary" onClick={toggleModal}>
//         Create Ambulance Type
//       </Button>
//       <Modal isOpen={modal} toggle={toggleModal}>
//         <ModalHeader toggle={toggleModal}>Create Ambulance Type</ModalHeader>
//         <ModalBody>
//           <Form>
//             <FormGroup>
//               <Label for="farePerKm">Fare Per KM</Label>
//               <Input
//                 type="number"
//                 id="farePerKm"
//                 value={farePerKm}
//                 onChange={e => setFarePerKm(e.target.value)}
//               />
//             </FormGroup>
//             <FormGroup>
//               <Label for="fixedPrice">Fixed Price</Label>
//               <Input
//                 type="number"
//                 id="fixedPrice"
//                 value={fixedPrice}
//                 onChange={e => setFixedPrice(e.target.value)}
//               />
//             </FormGroup>
//             <FormGroup>
//               <Label for="name">Name</Label>
//               <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
//                 <DropdownToggle caret>{name}</DropdownToggle>
//                 <DropdownMenu>
//                   <DropdownItem onClick={() => setName("ADVANCED")}>
//                     ADVANCED
//                   </DropdownItem>
//                   <DropdownItem onClick={() => setName("BASIC")}>
//                     BASIC
//                   </DropdownItem>
//                 </DropdownMenu>
//               </Dropdown>
//             </FormGroup>
//           </Form>
//         </ModalBody>
//         <ModalFooter>
//           <Button color="primary" onClick={createAmbulanceType}>
//             Create
//           </Button>{" "}
//           <Button color="secondary" onClick={toggleModal}>
//             Cancel
//           </Button>
//         </ModalFooter>
//       </Modal>
//     </div>
//   )
// }
