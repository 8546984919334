import PropTypes from 'prop-types';
import React, { useState, useEffect } from "react";
import { Row, Col, CardBody, Card, Container, Label, Form, FormFeedback, Input } from "reactstrap";
import { connect, useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import withRouter from 'components/Common/withRouter';
import * as Yup from "yup";
import { useFormik } from "formik";
import { loginUser, apiError } from "../../store/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logoSm from "../../assets/images/logo-sm.png";
import { LoadingButton } from 'pages/Utility/loading';

const Login = props => {
  const dispatch = useDispatch();
  const [userLogin, setUserLogin] = useState([]);
  const [showButton, setShowButton] = useState(true);
  const { user } = useSelector(state => ({
    user: state.Account.user,
  }));

  useEffect(() => {
    if (user && user) {
      setUserLogin({
        username: user.username,
        password: user.password
      });
    }
  }, [user]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: userLogin.username,
      password: userLogin.password,
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your User Name"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate, showToast))
    }
   
  });

  const showToast = (response) => {
      if (response?.user) {
        console.log(response)
        
        toast.success("Logged in successfully!");

      } else {
        toast.error("Trouble logging in ");
        console.log(response)
        setShowButton(true)
      }
    
  };
  
  document.title = "Login | hearts - React Admin & Dashboard  ";
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">
                      Welcome Back !
                    </h5>
                    <p className="text-white-50">
                      Sign in to continue to hearts.
                    </p>
                    <Link to="/" className="logo logo-admin">
                      <img src={logoSm} height="24" alt="logo" />
                    </Link>
                  </div>
                </div>

                <CardBody className="p-4">
                  <div className="p-3">
                    <Form className="mt-4"
                      onSubmit={(e) => {
                        e.preventDefault();
                        setShowButton(false)
                        validation.handleSubmit();
                       
                        return false;
                      }}
                      action="#">

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="username">Email</Label>
                        <Input
                          name="username"
                          className="form-control"
                          placeholder="Enter Email"
                          type="email"
                          id="username"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={
                            validation.touched.username && validation.errors.username ? true : false
                          }
                        />
                        {validation.touched.username && validation.errors.username ? (
                          <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="userpassword">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          className="form-control"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3 row">
                        <div className="col-sm-6">
                        
                        </div>
                        <div className="col-sm-6 text-end">
                          {showButton
                            ? <button  className="btn btn-primary w-md waves-effect waves-light" type="submit"  >Log In</button>:
                            <LoadingButton />
                            }
                          
                          
                        </div>
                      </div>

                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
              <p>
                  forgot password? <Link to="/pages-forgot-pwd" className="fw-medium text-primary">
                    Get Otp here
                  </Link>
                </p>
                </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
);

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
};