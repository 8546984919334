import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams,
} from "react-router-dom"
import { get } from "../../helpers/api_helper"
import ReviewCard from "../../components/Common/ReviewCard"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
function ReviewsSlug() {
  const { id } = useParams()
  const [review, setReview] = useState(null)
  useEffect(() => {
    const fetchReview = async () => {
      try {
        const response = await get(`/reviews/${id}`)
        setReview(response)
      } catch (error) {
        console.error("Error fetching Review:", error)
      }
    }
    fetchReview()
  }, [id])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col md="7">
              <Breadcrumbs maintitle="hearts" title="Review" />
            </Col>
            <Col md="5" className="align-self-end" />
          </Row>
          <Row>
            <ReviewCard key={id} review={review} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ReviewsSlug
