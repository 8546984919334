
import React, { useState } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { post, del, get, put } from "../../helpers/api_helper"
import { toast } from "react-toastify"
import { LoadingButton } from "pages/Utility/loading"

const IncomingBookingModal = ({
  isModalOpen,
  toggleModal,
  modalType,
  booking,
}) => {
  const navigate = useNavigate()
  const [clicked, setClicked] = useState(false)
  const handelReject = async (id) => {
    try {
      setClicked(true)
      await put(`/bookings/${booking?.id}`, { status: "REJECTED" })
      toast.success("rejected successfully")
      toggleModal()
      setClicked(false)
    } catch (error) {
      toast.error("failed to REjECT")
      setClicked(false)
      console.error("failed to REjECT")
    }
  }
  const handelAccept = async (id) => {
    try {
      setClicked(true)
      await put(`/bookings/${booking?.id}`, { status: "ACCEPTED" })
      toast.success("accepted successfully")
      navigate(`/bookings/${booking?.id}`)
      setClicked(false)
      toggleModal()
    } catch (error) {
      setClicked(false)
      toast.error("failed to accept")
      console.error("failed to accept")
    }
  }
  const handleRedirect = () => {
    setClicked(true)
    navigate(`/bookings/${booking?.id}`)
    setClicked(false)
    toggleModal()
  }

  return (
    <Modal isOpen={isModalOpen} toggle={toggleModal} size={modalType}>
      <ModalHeader toggle={toggleModal}>New Booking</ModalHeader>
      <ModalBody>
        {booking ? (
          <>
            <p>
              <strong>Client Phone:</strong> {booking?.client?.phone_number}
            </p>
            <p>
              <strong>Driver :</strong> {booking?.driver?.phone_number}
            </p>
            <p>
              <strong>Distance:</strong> {booking?.distance/1000 }{' km'}
            </p>
            <p>
              <strong>Duration:</strong> {booking?.duration}
            </p>
            <p>
              <strong>Price:</strong> {booking?.price}{' Br'}
            </p>
            <p>
              <strong>Provider:</strong> {booking?.provider.name}
            </p>
            <p>
              <strong>Pickup Location:</strong> {booking?.pickup_location?.address}
            </p>
            <p>
              <strong>Dropoff Location :</strong>{" "}
              {booking?.dropoff_location?.address}
            </p>
            <p>
              <strong>Status:</strong> {booking?.status}
            </p>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </ModalBody>
      <ModalFooter>
        {
          !clicked ? (
            <div>
                    <Button color="primary" onClick={handleRedirect}>
                      View Booking
                    </Button>{" "}
                    <Button color="success" onClick={handelAccept}>
                      Accept Booking
                    </Button>{" "}
                    <Button color="danger" onClick={handelReject}>
                      Reject Booking
                    </Button>{" "}
                    <Button color="secondary" onClick={toggleModal}>
                      Close
                    </Button>       
        </div>
          ):
          <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <LoadingButton />
          </div>
        }
            </ModalFooter>
        
    </Modal>
  )
}

export default IncomingBookingModal
