export default function validatePhoneNumber(phone_number) {
  // Check if the phone number starts with '+251' and has a total length of 13
  if (!phone_number.startsWith('+251') || phone_number.length !== 13) {
    console.log(false);
    return false;
  }

  // Check if all characters after '+251' are digits
  const numberPart = phone_number.slice(4); // Get the part after '+251'
  const isValid = /^\d{9}$/.test(numberPart); // Check if it contains exactly 9 digits

  console.log(isValid);
  return isValid;
}