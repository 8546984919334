import React, { useEffect, useState } from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Dropdown,
} from "reactstrap"
// import { toast } from "react-toastify"
import { post, del, get, put } from "../../helpers/api_helper"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { AddManager } from "helpers/backend_helper"
import { LoadingButton } from "pages/Utility/loading"
import validatePhoneNumber from "utils/phone_number_validator"

const CreateManagerModal = ({
  isModalOpen,
  toggleModal,
  modalType,
  handleManagerNew,
}) => {
  const [username, setUserName] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [phone_number, setPhone_number] = useState("")
  const [roleId, setRoleId] = useState("")
  const [provider_id, setProvider_id] = useState("")
  const [is_active, setIs_active] = useState(true)
  const [providers, setProviders] = useState([])
  const [showButton, setShowButton] = useState(true);
  const [logedinRoleId, setlogedinRoleId] = useState("")
  const [logedinProvider, setLogedinProvider] = useState("")

  async function getRole() {
    const userString = localStorage.getItem("authUser")
    const user = JSON.parse(userString)
    if (user.roleId != "1") {
      setProvider_id(user.provider_id)
    }
    setlogedinRoleId(user.roleId)
    setLogedinProvider(user.provider_id)
  }
  useEffect(() => {
    getRole()
  }, [])

  function allFilled() {
    return (
      username !== "" &&
      name !== "" &&
      email !== "" &&
      password !== "" &&
      phone_number !== "" &&
      roleId !== "" &&
      provider_id !== "" &&
      providers !== ""
    )
  }
  async function createManager(event) {
    if (allFilled() && validatePhoneNumber(phone_number)) {
      setShowButton(false)
      event.preventDefault()
      try {
        const response = await AddManager({
          username,
          password,
          roleId,
          provider_id,
          name,
          email,
          phone_number,
          is_active,
          photo: null,
        })

        // to be checked
        if (response.status >= 200 && response.status < 300) {
          toast.success("Manager created successfully!")
          toggleModal(modalType)
          handleManagerNew(response.data)
        } else {
          console.error(response)
          toast.error("Error creating Manager. Please try again.")
        setShowButton(true)
        }
      } catch (error) {
        console.error("Network error:", error)
        toast.error(
          error.response.data.error || "Network error. Please try again later."
        )
        setShowButton(true)
      }
    } else {
      toast.error("Error fill all filleds or.phone number should be +251 format and 12 degits long.")
      setShowButton(true)
    }
  }
  // console.log( provider_id)

  useEffect(() => {
    get("/providers")
      .then(data => setProviders(data))
      .catch(error => console.error(error))
  }, [])
  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Modal
        isOpen={isModalOpen}
        toggle={() => toggleModal(modalType)}
        size={modalType === "xlarge" ? "xl" : ""}
        scrollable={modalType === "scroll"}
      >
        <ModalHeader toggle={() => toggleModal(modalType)}>
          Manager Information
        </ModalHeader>
        <ModalBody>
          <form>
            <div className="mb-3">
              <label htmlFor="username" className="form-label">
                Username
              </label>
              <input
                required
                type="text"
                className="form-control"
                id="name"
                value={username}
                onChange={ev => setUserName(ev.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                required
                type="text"
                className="form-control"
                id="name"
                value={name}
                onChange={ev => setName(ev.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                required
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={ev => setEmail(ev.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                required
                type="password"
                className="form-control"
                id="password"
                value={password}
                onChange={ev => setPassword(ev.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="phone_number" className="form-label">
                Phone Number
              </label>
              <input
                required
                type="tel"
                className="form-control"
                id="phone_number"
                value={phone_number}
                onChange={ev => setPhone_number(ev.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="phone_number" className="form-label">
                Role
              </label>
              <select
                className="form-control"
                required
                value={roleId}
                onChange={e => setRoleId(e.target.value)}
              >
                <option value="">Select a Role</option>
                {/* <option value={"1"} >SUPER_ADMIN</option> */}
                <option value={"2"}>Provider_ADMIN</option>
                <option value={"3"}>PROVIDER_OPERATOR</option>
              </select>
            </div>
            {
              logedinRoleId == "1" ? (
                <div className="mb-3">
                  <label htmlFor="provider_id" className="form-label">
                    Provider{" "}
                  </label>
                  <select
                    className="form-control"
                    id="provider_id"
                    value={provider_id}
                    onChange={e => setProvider_id(e.target.value)}
                  >
                    <option value="">Select a provider</option>
                    {providers &&
                      providers.map(pro => (
                        <option key={pro?.id} value={pro?.id}>
                          {pro?.name}
                        </option>
                      ))}
                  </select>
                </div>
              ) : null
              //   <div className="mb-3">
              //   <label htmlFor="provider_id" className="form-label">
              //     Provider{" "}
              //   </label>
              //   <input
              //     className="form-control"
              //     id="provider_id"
              //     value={"you already have a provider"}
              //     onChange={e => setProvider_id(logedinProvider)}
              //     contentEditable = {false}
              //   >

              //   </input>
              // </div>
            }

            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="is_active"
                checked={is_active}
                onChange={ev => setIs_active(ev.target.checked)}
              />
              <label className="form-check-label" htmlFor="is_active">
                Is Active
              </label>
            </div>
            <ModalFooter>
              <Button color="secondary" onClick={() => toggleModal(modalType)}>
                Close
              </Button>
              {showButton ? <Button
                            color="primary"
                            type="submit"
                            onClick={event => createManager(event)}
                          >
                            Add Manager
                          </Button>:
<LoadingButton /> }
      
              
            </ModalFooter>
          </form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default CreateManagerModal
