import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams,
} from "react-router-dom"
import { get } from "../../helpers/api_helper"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const ReviewCard = ({ review }) => {
  return (
    <Card>
      <CardBody>
        <h4 className="card-title mb-4">Client Review</h4>
        <div className=" d-flex justify-content-between">
          <div className="d-flex align-items-center mb-3">
            <img
              src={review?.client?.photo || user3}
              alt=""
              className="avatar-sm rounded-circle me-2"
              onError={e => {
                e.target.src = user3
              }}
            />
            <div>
              <h6 className="mb-0">{review.client?.name}</h6>
              <h6 className="mb-0">{review.client?.phone_number}</h6>
              <small className="text-muted">Client</small>
            </div>
          </div>
          <Link to={`/drivers/${review.driver?.id}`}>
            <div className="d-flex align-items-center mb-3">
              <img
                src={review.driver?.photo || user3}
                onError={e => {
                  e.target.src = user3
                }}
                alt=""
                className="avatar-sm rounded-circle me-2"
              />
              <div>
                <h6 className="mb-0">{review.driver?.username}</h6>
                <small className="text-muted">Driver</small>
              </div>
            </div>
          </Link>
        </div>

        <p className="text-muted mb-3 pb-4">{review.comment}</p>
        <div className="float-end mt-2">
          <Link to="#" className="text-primary">
            <i className="mdi mdi-arrow-right h5"></i>
          </Link>
        </div>
        <div className="d-flex align-items-center">
          <div className="me-2">
            <i className="mdi mdi-star text-warning"></i>
            <span className="fw-bold">{review.rating}</span>/5
          </div>
          <div>
            <small className="text-muted">
              {review.Request[0]?.provider?.name}
            </small>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default  ReviewCard
