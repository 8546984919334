import React, { useEffect, useState } from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container,
  Button,
  ButtonGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { post, del, get, put } from "../../helpers/api_helper"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { number } from "yup"
import user3 from "../../assets/images/users/user-3.jpg"
import car3 from "../../assets/images/driver_location.png"

function CreateBooking() {
  const [price, setprice] = useState(null)
  const [driver_id, setdriver_id] = useState("")
  const [selectedDriver, setSelectedDriver] = useState(null)
  const [selectedProvider, setSelectedProvider] = useState({})
  const [clientPhone, setClientPhone] = useState("")
  const [pickup, setpickup] = useState(null)
  const [dropoff, setdropoff] = useState(null)
  const [distance, set_distance] = useState("")
  const [duration, set_duration] = useState("")
  const [provider_id, setProvider_id] = useState("")
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const [drivers, setdrivers] = useState([])
  const [isOpen, setIsOpen] = useState(true)
  const [isCreating, setIsCreating] = useState(false)
  const toggleList = e => {
    e.preventDefault()
    setIsOpen(!isOpen)
  }
  const navigate = useNavigate()
  const handleDriverSelect = (e, driver) => {
    e.preventDefault()
    setSelectedDriver(driver)
    setdriver_id(driver.id)
    toggleList(e)
  }

  // const [providers, setProviders] = useState([])

  async function getdriver() {
          const response = await GetDrivers({params: { providerId:authUser.provider_id, is_active:true, all:true, }});
        setdrivers(response.data)
        }
      //   else{
      //   const response = await GetDrivers({params: { providerId:authUser.provider_id, is_active:true, all:true, }});
      //   setdrivers(response.data)
      //   console.log("/////////////////////")
      // }
  

    async function getProvider() {
      const response = await GetProviderById(authUser.provider_id)
      // setProviders([response])
      if (response) {
        setSelectedProvider(response);
        setProvider_id(response.id)
     }
  
    }

  useEffect(() => {
    getdriver()
    getProvider()
    // getPickupLocation
  }, [])

  if (authUser.roleId == "1") {
    // navigate to /booking
    navigate("/bookings")
  }
  // useEffect(() => {
  //   console.log("prov", selectedProvider)
  //   console.log("pickup", pickup)
  //   console.log("dropoff", dropoff)
  //   console.log("duration", duration)
  //   console.log("distance", distance)
  // }, [selectedProvider, duration, distance])

  //   reminder move to helper
  function allFilled() {
    // console.log(
    //   "distance:",
    //   distance,
    //   "duration:",
    //   duration,
    //   "price:",
    //   price,
    //   "driver_id:",
    //   driver_id,
    //   "clientPhone:",
    //   clientPhone,
    //   "pickup:",
    //   pickup,
    //   "dropoff:",
    //   dropoff,
    //   "provider_id:",
    //   provider_id
    // )
    return (
      distance !== "" &&
      duration !== "" &&
      price !== "" &&
      driver_id !== "" &&
      clientPhone !== "" &&
      pickup !== "" &&
      dropoff !== "" &&
      provider_id !== ""
      //   providers
    )
  }
  function convertDistanceToKM(distanceString) {
    let [distance, unit] = distanceString.split(" ")
    let distanceNumber = parseFloat(distance)
    let conversionFactor = 1
    if (unit === "km") {
      conversionFactor = 1
    } else if (unit === "m") {
      conversionFactor = 0.001
    }
    let distanceInKM = distanceNumber * conversionFactor
    return distanceInKM
  }
  function getAmbulancePricing(driver) {
    // Extract the ambulance type ID from the driver's vehicle
  
    // Find the AmbulanceType associated with the selected driver's ambulance
    const selectedAmbulanceType = driver.vehicle.ambulance_type

    console.log("selectedAmbulanceType", selectedAmbulanceType)
    if (selectedAmbulanceType) {
      const farePerKm = selectedAmbulanceType.farePerKm
      const fixedPrice = selectedAmbulanceType.fixedPrice

      return { farePerKm, fixedPrice }
    } else {
      return { farePerKm: null, fixedPrice: null }
    }
  }

  async function createBookingNow(event) {
    try {
      setIsCreating(true)
      event.preventDefault()
      let _distance = convertDistanceToKM(distance)
      // console.log(_distance)
      const { farePerKm, fixedPrice } = getAmbulancePricing(
        selectedDriver,
        // selectedProvider
      )
      console.log(
        "fairs",
        farePerKm,
        fixedPrice,
        selectedDriver,
        selectedProvider
      )
      let _price = _distance * farePerKm + fixedPrice
      console.log("calculated: price", _price)
      setprice(_price)
      setTimeout(async () => {
        if (allFilled()) {
          event.preventDefault()
          try {
            const response = await post("/bookings", {
              distance: _distance,
              duration,
              price: _price,
              driver_id,
              clientPhone,
              pickup,
              dropoff,
              provider_id,
              status: "ACCEPTED",
            })
            console.log("response::", response.data)
            if (response.data) {
              toast.success("Booking created successfully!")
              navigate(`/bookings/${response.data.id}`)
            } else {
              console.error("Error creating Booking:", response.status)
              toast.error("Error creating Booking. Please try again.")
              setIsCreating(false)
            }
          } catch (error) {
            console.error("Network error:", error)
            toast.error("Network error. Please try again later.")
            setIsCreating(false)
          }
        } else {
          toast.error("Error fill all filleds. Please try again.")
          setIsCreating(false)
        }
      }, 2000)
    } catch (e) {
      setIsCreating(false)
    }
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row className="align-items-center row justify-content-end">
            <Breadcrumbs maintitle="hearts" title="Bookings" />
          </Row>
          <CreateMap
            setpickup={setpickup}
            setdropoff={setdropoff}
            set_distance={set_distance}
            set_duration={set_duration}
          />
          <Row mb="2" className="align-items-center row justify-content-end">
            <form>
              <div className="mb-2">
                <label htmlFor="client_id" className="md-2 form-label">
                  Client Phone *
                </label>
                <div>
                  <span>+251</span>
                  <input
                    type="tel"
                    pattern="^[9|7]\d{8,9}$"
                    minLength="9"
                    maxLength="9"
                    className="form-control md-8"
                    id="client_id"
                    value={clientPhone}
                    onChange={e => setClientPhone(e.target.value)}
                  ></input>
                </div>
              </div>

              {/* <div className="mb-3">
                <label htmlFor="provider" className="form-label">
                  Driver
                </label>
                <select
                  className="form-control"
                  id="driver_id"
                  value={driver_id}
                  onChange={e => {
                    setdriver_id(e.target.value)
                    setSelectedDriver(
                      drivers.find(
                        driver => driver.id.toString() === e.target.value
                      )
                    )
                    console.log(selectedDriver)
                  }}
                >
                  <option value="">Select a Driver</option>
                  {Array.isArray(drivers) &&
                    drivers.length > 0 &&
                    drivers.map(pro => (
                      <option key={pro?.id} value={pro?.id}>
                        {pro?.username}
                        {" |  "}
                        {pro?.phone_number} {" |  "}
                        {pro?.name}
                      </option>
                    ))}
                </select>
              </div> */}

              <div className="mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <button className="btn btn-secondary" onClick={toggleList}>
                    {selectedDriver
                      ? `${selectedDriver.username} | ${selectedDriver.phone_number} | ${selectedDriver.name}`
                      : "Select a Driver"}
                  </button>
                  <button
                    className={`btn btn-${isOpen ? "danger" : "primary"}`}
                    onClick={toggleList}
                  >
                    {isOpen ? "Close" : "Open"}
                  </button>
                </div>
                {isOpen && (
                  <ul className="list-group mt-2">
                    {Array.isArray(drivers) &&
                      drivers.length > 0 &&
                      drivers.map(driver => (
                        <li
                          style={{
                            display: "flex",
                            width: "",
                            justifyContent: "space-between",
                          }}
                          key={driver.id}
                          className={`list-group-item ${
                            driver === selectedDriver ? "active" : ""
                          } mb-3`}
                          onClick={e => handleDriverSelect(e, driver)}
                        >
                          <div className="d-flex justify-content-between w-100">
                            <div>
                              <img
                                src={driver?.photo || user3}
                                alt={driver?.username}
                                className="avatar-sm rounded-circle me-2"
                                onError={e => {
                                  e.target.src = user3
                                }}
                              />
                              <div>
                                <h6 className="mb-0">{driver?.name}</h6>

                                <h6 className="mb-0">{driver?.username}</h6>
                                <small className="text-muted">
                                  {driver?.phone_number}
                                </small>
                              </div>
                            </div>

                            <div className="p-3 d-flex justify-content-center">
                              <img
                              style={{ width: "50px", height: "50px", borderRadius: "30%" }}
                                src={driver?.vehicle?.photo || car3}
                                alt={driver?.vehicle?.name}
                                className=""
                                onError={e => {
                                  e.target.src = car3
                                }}
                              />
                              <div>
                                <h6 className="mb-0">
                                  {driver?.vehicle?.plate_number}
                                </h6>

                                <h6 className="mb-0">
                                  {driver?.vehicle?.car_name}
                                </h6>
                                <small className="text-muted">
                                  {driver?.vehicle?.ambulance_type?.name}
                                </small>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                )}
              </div>

              {/* {authUser.provider_id === null && authUser.role_id == "1" ? (
                <>
                  <div className="mb-3">
                    <label htmlFor="provider" className="form-label">
                      Provider
                    </label>
                    <select
                      className="form-control"
                      id="provider"
                      value={provider_id}
                      onChange={e => setProvider_id(e.target.value)}
                    >
                      <option value="">Select a Provider</option>
                      {Array.isArray(providers) &&
                        providers.length > 0 &&
                        providers.map(pro => (
                          <option key={pro?.id} value={pro?.id}>
                            {pro?.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </>
              ) : null} */}
              <Row>
                <Button
                  color={!isCreating ? "primary" : "secondary"}
                  type="submit"
                  style={{ minHeight: "60px" }}
                  disabled={isCreating}
                  onClick={e => createBookingNow(e)}
                >
                  Add Booking
                </Button>
              </Row>
            </form>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default CreateBooking

// import { useRef } from "react"

// import { FaLocationArrow, FaTimes } from "react-icons/fa"
// import {
//   GoogleMap,
//   Marker,
//   DirectionsRenderer,
//   useJsApiLoader,
//   Autocomplete,
// } from "@react-google-maps/api"
// import zIndex from "@mui/material/styles/zIndex"

// const center = { lat: 9.007923, lng: 38.767821 }

// function CreateMap({ setpickup, setdropoff }) {
//   const { isLoaded } = useJsApiLoader({
//     googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
//     libraries: ["places"],
//   })

//   const [map, setMap] = useState(null)
//   const [directionsResponse, setDirectionsResponse] = useState(null)
//   const [distance, setDistance] = useState("")
//   const [duration, setDuration] = useState("")

//   const originRef = useRef()
//   const destinationRef = useRef()

//   const calculateRoute = async () => {
//     if (!originRef.current.value || !destinationRef.current.value) return

//     const directionsService = new window.google.maps.DirectionsService()
//     const results = await directionsService.route({
//       origin: originRef.current.value,
//       destination: destinationRef.current.value,
//       travelMode: window.google.maps.TravelMode.DRIVING,
//     })

//     //
//     setDirectionsResponse(results)
//     setDistance(results.routes[0].legs[0].distance.text)
//     setDuration(results.routes[0].legs[0].duration.text)

//     if (originRef.current && destinationRef.current) {
//       const originPlace = originRef.current.getPlace()
//       const destinationPlace = destinationRef.current.getPlace()

//       if (originPlace && originPlace.geometry) {
//         const { lat, lng } = originPlace.geometry.location
//         setpickup({
//           address: originRef.current.value,
//           latitude: lat(),
//           longitude: lng(),
//         })
//         console.log({
//           address: originRef.current.value,
//           latitude: lat(),
//           longitude: lng(),
//         })
//       }

//       if (destinationPlace && destinationPlace.geometry) {
//         const { lat, lng } = destinationPlace.geometry.location
//         setdropoff({
//           address: destinationRef.current.value,
//           latitude: lat(),
//           longitude: lng(),
//         })
//         console.log({
//           address: destinationRef.current.value,
//           latitude: lat(),
//           longitude: lng(),
//         })
//       }
//     }
//   }

//   const clearRoute = () => {
//     setDirectionsResponse(null)
//     setDistance("")
//     setDuration("")
//     originRef.current.value = ""
//     destinationRef.current.value = ""
//   }

//   if (!isLoaded) {
//     return <p>Loading...</p>
//   }

//   return (
//     <div>
//       {/* className="page-content"
//        className="container-fluid" */}
//       <div>
//         <Container fluid>
//           <Row mb={2}>
//             <Col className="position-relative" xs={12}>
//               <GoogleMap
//                 center={center}
//                 zoom={15}
//                 mapContainerStyle={{
//                   width: "100%",
//                   height: "600px",
//                   marginBottom: "15px",
//                 }}
//                 options={{
//                   zoomControl: false,
//                   streetViewControl: false,
//                   mapTypeControl: false,
//                   fullscreenControl: false,
//                 }}
//                 onLoad={map => setMap(map)}
//               >
//                 <Marker position={center} />
//                 {directionsResponse && (
//                   <DirectionsRenderer directions={directionsResponse} />
//                 )}
//               </GoogleMap>
//             </Col>

//             <Row className="d-flex align-items-center justify-content-center">
//               <Container>
//                 <Row className="mb-3">
//                   <Col md="5">
//                     <InputGroup className="d-flex w-full">
//                       <Autocomplete>
//                         <Input
//                           type="text"
//                           placeholder="Origin"
//                           innerRef={originRef}
//                         />
//                       </Autocomplete>
//                     </InputGroup>
//                   </Col>
//                   <Col md="5">
//                     <InputGroup>
//                       <Autocomplete>
//                         <Input
//                           type="text"
//                           placeholder="Destination"
//                           innerRef={destinationRef}
//                         />
//                       </Autocomplete>
//                     </InputGroup>
//                   </Col>
//                   <Col className="d-flex align-items-center">
//                     <ButtonGroup>
//                       <Button color="primary" onClick={calculateRoute}>
//                         Calculate Route
//                       </Button>
//                       <Button color="danger" onClick={clearRoute}>
//                         <FaTimes />
//                       </Button>
//                     </ButtonGroup>
//                   </Col>
//                 </Row>
//                 <Row className="mb-3">
//                   <Col>
//                     <p>Distance: {distance}</p>
//                   </Col>
//                   <Col>
//                     <p>Duration: {duration}</p>
//                   </Col>
//                   <Col className="d-flex align-items-center">
//                     <Button
//                       color="light"
//                       onClick={() => {
//                         map.panTo(center)
//                         map.setZoom(15)
//                       }}
//                     >
//                       <FaLocationArrow />
//                     </Button>
//                   </Col>
//                 </Row>
//               </Container>
//             </Row>
//           </Row>
//         </Container>
//       </div>
//     </div>
//   )
// }

import { useRef } from "react"
import {
  GoogleMap,
  Marker,
  DirectionsRenderer,
  useJsApiLoader,
  Autocomplete,
} from "@react-google-maps/api"
import { FaLocationArrow, FaTimes } from "react-icons/fa"
import { GetDrivers, GetProviderById, GetProviders } from "helpers/backend_helper"

const center = { lat: 9.007923, lng: 38.767821 }

function CreateMap({ setpickup, setdropoff, set_distance, set_duration }) {
  // const { isLoaded } = useJsApiLoader({
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  //   libraries: ["places"],
  // })

  const [map, setMap] = useState(null)
  const [directionsResponse, setDirectionsResponse] = useState(null)
  const [distance, setDistance] = useState("")
  const [duration, setDuration] = useState("")

  const originRef = useRef()
  const destinationRef = useRef()
  const originAutocompleteRef = useRef(null)
  const destinationAutocompleteRef = useRef(null)

  const calculateRoute = async () => {
    if (!originRef.current.value || !destinationRef.current.value) return

    const directionsService = new window.google.maps.DirectionsService()
    const results = await directionsService.route({
      origin: originRef.current.value,
      destination: destinationRef.current.value,
      travelMode: window.google.maps.TravelMode.DRIVING,
    })

    setDirectionsResponse(results)
    setDistance(results.routes[0].legs[0].distance.text)
    setDuration(results.routes[0].legs[0].duration.text)

    const originPlace = originAutocompleteRef.current.getPlace()
    const destinationPlace = destinationAutocompleteRef.current.getPlace()

    function higher_lever_call() {
      //for the passed in props
      if (set_distance != null && set_duration != null) {
        set_distance(results.routes[0].legs[0].distance.text)
        set_duration(results.routes[0].legs[0].duration.text)
      }
      if (originPlace && originPlace.geometry && setpickup != null) {
        const { lat, lng } = originPlace.geometry.location
        setpickup({
          address: originRef.current.value,
          latitude: lat(),
          longitude: lng(),
        })
        console.log({
          address: originRef.current.value,
          latitude: lat(),
          longitude: lng(),
        })
      }

      if (destinationPlace && destinationPlace.geometry && setdropoff != null) {
        const { lat, lng } = destinationPlace.geometry.location
        setdropoff({
          address: destinationRef.current.value,
          latitude: lat(),
          longitude: lng(),
        })
        console.log({
          address: destinationRef.current.value,
          latitude: lat(),
          longitude: lng(),
        })
      }
    }
    higher_lever_call()
  }

  const clearRoute = () => {
    setDirectionsResponse(null)
    setDistance("")
    setDuration("")
    originRef.current.value = ""
    destinationRef.current.value = ""
    if (!(!setpickup || !setdropoff || !set_distance || !set_duration)) {
      setpickup(null)
      setdropoff(null)
      set_distance(null)
      set_duration(null)
    }
  }

  // if (!isLoaded) {
  //   return <p>Loading...</p>
  // }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Row className="vh-60">
          <div className="col-12 position-relative">
            <GoogleMap
              center={center}
              zoom={15}
              mapContainerStyle={{
                width: "100%",
                height: "600px",
                marginBottom: "15px",
              }}
              options={{
                zoomControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
              }}
              onLoad={map => setMap(map)}
            >
              {/* <Marker position={center} /> */}
              {directionsResponse && (
                <DirectionsRenderer directions={directionsResponse} />
              )}
            </GoogleMap>
          </div>
          <div className="col d-flex align-items-center justify-content-center">
            <div className="container">
              <Row className="mb-3">
                <Col md="5">
                  <InputGroup className="w-100">
                    <Autocomplete
                      className="w-100"
                      onLoad={autocomplete =>
                        (originAutocompleteRef.current = autocomplete)
                      }
                      onPlaceChanged={() => {
                        const place = originAutocompleteRef.current.getPlace()
                        if (place.geometry) {
                          const { lat, lng } = place.geometry.location
                          setpickup({
                            address: originRef.current.value,
                            latitude: lat(),
                            longitude: lng(),
                          })
                        }
                      }}
                    >
                      <input
                        type="text"
                        className="form-control h-100"
                        placeholder="Origin"
                        ref={originRef}
                      />
                    </Autocomplete>
                  </InputGroup>
                </Col>
                <Col md="5">
                  <InputGroup className="w-100">
                    <Autocomplete
                      className="w-100"
                      onLoad={autocomplete =>
                        (destinationAutocompleteRef.current = autocomplete)
                      }
                      onPlaceChanged={() => {
                        const place =
                          destinationAutocompleteRef.current.getPlace()
                        if (place.geometry) {
                          const { lat, lng } = place.geometry.location
                          setdropoff({
                            address: destinationRef.current.value,
                            latitude: lat(),
                            longitude: lng(),
                          })
                        }
                      }}
                    >
                      <input
                        type="text"
                        className="form-control w-100"
                        placeholder="Destination"
                        ref={destinationRef}
                      />
                    </Autocomplete>
                  </InputGroup>
                </Col>

                <Col md="2">
                  <ButtonGroup>
                    <Button color="primary" onClick={calculateRoute}>
                      Calculate Route
                    </Button>
                    <Button color="danger" onClick={clearRoute}>
                      <FaTimes />
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <div className="col">
                  <p>Distance: {distance}</p>
                </div>
                <div className="col">
                  <p>Duration: {duration}</p>
                </div>
                <div className="col d-flex align-items-center">
                  <button
                    className="btn btn-light"
                    onClick={() => {
                      map.panTo(center)
                      map.setZoom(15)
                    }}
                  >
                    <FaLocationArrow />
                  </button>
                </div>
              </Row>
            </div>
          </div>
        </Row>
      </div>
    </div>
  )
}
