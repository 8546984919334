import React, { useState, useEffect } from "react"
import "./SkeletonLoader.css"
import {
  Container,
  Row,
  Col,
  InputGroup,
  Input,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"
import { Link, useLocation, useNavigate } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"

import CreateProviderModal from "./CreateProviderModal"

import { toast } from "react-toastify"
import { post, del, get, put } from "../../helpers/api_helper"

function Providers() {
  const [providers, setProviders] = useState([])
  const [filteredProviders, setFilteredProviders] = useState([])
  const [loading, setLoading] = useState(true)
  const [singlebtn, setSinglebtn] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [roleId, setRoleId] = useState("")
  const [selectedItem, setSelectedItem] = useState([
    <span className="badge bg-primary badge-xl">All</span>,
    0,
  ])
  const [providerStatus, setProviderStatus] = useState(0)

  useEffect(() => {

    const fetchProviders = async () => {
      try {
        const response = await get("/providers")
        const data = await response

        setProviders(data)
        setFilteredProviders(data)
      } catch (error) {
        console.error("Error fetching providers:", error)
        toast.error("failed to fetch")
      } finally {
        setLoading(false)
      }
    }

    fetchProviders()
  }, [])
  const navigate = useNavigate()
  async function getRole() {
    const userString = localStorage.getItem("authUser")
    const user = JSON.parse(userString)
    if (user.roleId !== "1") {
      navigate("/dashboard")
    }
  }
  useEffect(() => {
    getRole()
  }, [])

  const handleProviderNew = provider => {
    setProviders([...providers, provider])
  }
  const renderSkeletonRow = () => (
    <tr>
      <td>
        <div className="skeleton" style={{ width: "80%" }}></div>
      </td>
      <td>
        <div className="skeleton" style={{ width: "80%" }}></div>
      </td>
      <td>
        <div className="skeleton" style={{ width: "80%" }}></div>
      </td>
      <td>
        <div className="skeleton-avatar"></div>
      </td>
      <td>
        <div className="skeleton" style={{ width: "50%" }}></div>
      </td>
      <td>
        <div className="skeleton" style={{ width: "30%" }}></div>
      </td>
    </tr>
  )

  const filterProviders = () => {
    if (providers.length)
      return providers.filter(provider => {
        const searchRegex = new RegExp(searchTerm, "i")
        if (providerStatus === 0) {
          return (
            searchTerm === "" ||
            searchRegex.test(provider.name) ||
            searchRegex.test(provider.email) ||
            searchRegex.test(provider.phone_number)
          )
        }
        return providerStatus === 1
          ? provider.is_active &&
              (searchTerm === "" ||
                searchRegex.test(provider.name) ||
                searchRegex.test(provider.email) ||
                searchRegex.test(provider.phone_number))
          : !provider.is_active &&
              (searchTerm === "" ||
                searchRegex.test(provider.name) ||
                searchRegex.test(provider.email) ||
                searchRegex.test(provider.phone_number))
      })
  }

  useEffect(() => {
    setFilteredProviders(filterProviders())
  }, [providers, providerStatus, searchTerm])

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalType, setModalType] = useState("")

  const toggleModal = type => {
    setModalType(type)
    setIsModalOpen(!isModalOpen)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="align-items-center row justify-content-end">
            <Col md="4">
              <Breadcrumbs maintitle="hearts" title="Providers" />
            </Col>

            <Col md="8" className="d-flex justify-content-end">
            {roleId !== "3" &&(
           <div className="my-4 text-center">
           <Button
             color="primary"
             onClick={() => toggleModal("scroll")}
             className="m-0"
           >
             Create Provider
           </Button>
         </div> 
          )
          }
              <CreateProviderModal
                isModalOpen={isModalOpen}
                toggleModal={toggleModal}
                modalType={modalType}
                handleProviderNew = {handleProviderNew}
              />
            </Col>
          </Row>

          <Row className="relative d-flex align-items-center justify-content-end">
            <Col md="4" className="mb-3 mb-md-0">
              <Dropdown
                isOpen={singlebtn}
                toggle={() => setSinglebtn(!singlebtn)}
              >
                <DropdownToggle className="btn btn-secondary" caret>
                  {selectedItem[0] || "Status"}{" "}
                  <i className="mdi mdi-chevron-down" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => {
                      setProviderStatus(0)
                      setSelectedItem(["All", 0])
                    }}
                  >
                    <span
                      className="badge bg-primary badge-xl"
                      style={{ fontSize: "1rem" }}
                    >
                      All
                    </span>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setProviderStatus(1)
                      setSelectedItem(["Active", 1])
                    }}
                  >
                    <span
                      className="badge bg-success"
                      style={{ fontSize: "1rem" }}
                    >
                      Active
                    </span>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setProviderStatus(2)
                      setSelectedItem(["Inactive", 2])
                    }}
                  >
                    <span
                      className="badge bg-danger"
                      style={{ fontSize: "1rem" }}
                    >
                      Inactive
                    </span>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>
            <Col md="4">
              <form
                className="app-search d-block "
                style={{ display: "block" }}
              >
                <div className="position-relative">
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                  />
                  <span className="fa fa-search"></span>
                </div>
              </form>
            </Col>
          </Row>

          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone Number</th>
                <th scope="col">Photo</th>
                <th scope="col">Status</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <>
                  {renderSkeletonRow()}
                  {renderSkeletonRow()}
                  {renderSkeletonRow()}
                </>
              ) : (
                filteredProviders &&
                filteredProviders.length &&
                filteredProviders.map((providers, index) => (
                  <ProviderRow key={index} provider={providers} />
                ))
              )}
              
            </tbody>
          </table>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Providers

const ProviderRow = ({
  provider: { id, name, email, phone_number, photo, is_active },
}) => {
  return (
    <tr>
      <td>{name}</td>
      <td>{email}</td>
      <td>{phone_number}</td>
      <td>
        <img
          src={photo || user2}
          alt=""
          className="avatar-xs rounded-circle me-2"
          onError={e => (e.target.src = user2)}
        />
      </td>
      <td>
        <span className={`badge bg-${is_active ? "success" : "danger"}`}>
          {is_active ? "Active" : "Inactive"}
        </span>
      </td>
      <td>
        <div>
          <Link
            to={`${location.pathname}/${id}`}
            className="btn btn-primary btn-sm"
          >
            Edit
          </Link>
        </div>
      </td>
    </tr>
  )
}
