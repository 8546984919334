// import React, { useState, useEffect } from "react"
// import {
//   Container,
//   Button,
//   Modal,
//   ModalHeader,
//   ModalBody,
//   ModalFooter,
//   Form,
//   FormGroup,
//   Label,
//   Input,
// } from "reactstrap"
// import {
//   Row,
//   Col,
//   Card,
//   CardBody,
//   Dropdown,
//   DropdownToggle,
//   DropdownItem,
//   DropdownMenu,
// } from "reactstrap"
// import Dropzone from "react-dropzone"
// import user2 from "../../assets/images/users/user-2.jpg"
// import user3 from "../../assets/images/users/user-3.jpg"

// import { post, del, get, put } from "../../helpers/api_helper"

// //Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb"
// import {
//   BrowserRouter as Router,
//   Route,
//   Switch,
//   useParams,
// } from "react-router-dom"

// function MyProvider() {
//   const { id } = useParams()
//   const [providerData, setProviderData] = useState()

//   const [isEditing, setIsEditing] = useState(false)
//   const [editProviderDetail, seteditProviderDetail] = useState(false)
//   const [editedProviderData, seteditedProviderData] = useState(providerData)

//   const [isAmbulanceTypeEditing, setIsAmbulanceTypeEditing] = useState(false)
//   const [selectedAmbulanceType, setSelectedAmbulanceType] = useState(null)
//   const [selectedFiles, setselectedFiles] = useState()

//   const handleAcceptedFiles = acceptedFiles => {
//     if (acceptedFiles.length > 0) {
//       const file = acceptedFiles[0]
//       setselectedFiles(URL.createObjectURL(file))
//       seteditedProviderData(prevData => ({
//         ...prevData,
//         photo: URL.createObjectURL(file),
//       }))
//     }
//   }

//   function formatBytes(bytes, decimals = 2) {
//     if (bytes === 0) return "0 Bytes"
//     const k = 1024
//     const dm = decimals < 0 ? 0 : decimals
//     const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

//     const i = Math.floor(Math.log(bytes) / Math.log(k))
//     return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
//   }

//   const handleEdit = () => {
//     setIsEditing(true)
//   }

//   const handleDelete = () => {
//     // Add delete logic here
//   }

//   const handleAmbulanceTypeEdit = ambulanceType => {
//     setSelectedAmbulanceType(ambulanceType)
//     setIsAmbulanceTypeEditing(true)
//   }

//   const handleAmbulanceTypeDelete = ambulanceTypeId => {
//     // Add delete logic here
//     // /ambulancetype/delete/id {post}
//   }

//   const handleAmbulanceTypeCreate = () => {
//     setSelectedAmbulanceType(null)
//     setIsAmbulanceTypeEditing(true)
//     // /ambulancetypes/create {post}
//   }

//   // const handleEditProviderDetail = () => {}

//   useEffect(() => {
//     // Fetch the manager data from the server or other data source
//     const fetchManagerData = async () => {
//       try {
//         const response = await get(`/providers/${id}` )
//         const data = await response
//         setProviderData(data)
//       } catch (error) {
//         console.error("Error fetching providers data:", error)
//       }
//     }
//     fetchManagerData()
//   }, [id])

//   if (!providerData) {
//     return <div>Loading...</div>
//   }

//   return (
//     <React.Fragment>
//       <div className="page-content">
//         <Container fluid>
//           {/* Render Breadcrumbs */}
//           <Breadcrumbs
//             maintitle="hearts"
//             title="Providers"
//             breadcrumbItem={id}
//           />

//           {/* Provider Details */}

//           <div>
//             <h2>Provider Details</h2>
//             <Form>
//               {editProviderDetail ? (
//                 <>
//                   <FormGroup>
//                     <Label for="name">Name:</Label>
//                     <Input
//                       type="text"
//                       id="name"
//                       value={editedProviderData.name}
//                       onChange={e =>
//                         seteditedProviderData({
//                           ...editedProviderData,
//                           name: e.target.value,
//                         })
//                       }
//                       disabled={!editProviderDetail}
//                     />
//                   </FormGroup>

//                   <FormGroup>
//                     <Label for="image">Profile image</Label>
//                     <Dropzone
//                       onDrop={acceptedFiles => {
//                         handleAcceptedFiles(acceptedFiles)
//                       }}
//                     >
//                       {({ getRootProps, getInputProps }) => (
//                         <div
//                           className=" "
//                           style={{
//                             backgroundImage: `url(${editedProviderData.photo})`,
//                             // backgroundImage: selectedFiles
//                             //   ? `url(${selectedFiles})`
//                             //   : `url(${editedProviderData.photo})`,

//                             backgroundSize: "cover",
//                             backgroundPosition: "center",
//                             height: "100px", // Profile picture height
//                             width: "100px", // Profile picture width
//                             borderRadius: "50%", // Make it circular
//                             border: "2px solid #ccc", // Add a border for better visibility
//                             display: "flex", // Center content
//                             alignItems: "center", // Center content vertically
//                             justifyContent: "center", // Center content horizontally
//                           }}
//                         >
//                           <div
//                             className="dz-message needsclick"
//                             {...getRootProps()}
//                             style={{
//                               width: "10%",
//                               height: "10%",
//                               display: "flex",
//                               alignItems: "center",
//                               justifyContent: "center",
//                             }}
//                             {...getRootProps()}
//                           >
//                             <input {...getInputProps()} />
//                             {editProviderDetail ? (
//                               <div
//                                 className="mb-3"
//                                 style={{
//                                   position: "relative",
//                                   top: "50px",
//                                   left: "50px",
//                                   background: "rgba(255, 255, 255, 0.7)",
//                                   borderRadius: "50%",
//                                   padding: "5px",
//                                 }}
//                               >
//                                 <i className="mdi mdi-cloud-upload display-6 text-muted"></i>
//                               </div>
//                             ) : null}
//                             {/* <h4>Drop files here or click to upload.</h4> */}
//                           </div>
//                         </div>
//                       )}
//                     </Dropzone>
//                   </FormGroup>

//                   <FormGroup>
//                     <Label for="email">Email:</Label>
//                     <Input
//                       type="email"
//                       id="email"
//                       value={editedProviderData.email}
//                       onChange={e =>
//                         seteditedProviderData({
//                           ...editedProviderData,
//                           email: e.target.value,
//                         })
//                       }
//                       disabled={!editProviderDetail}
//                     />
//                   </FormGroup>
//                   <FormGroup>
//                     <Label for="phone_number">Phone Number:</Label>
//                     <Input
//                       type="tel"
//                       id="phone_number"
//                       value={editedProviderData.phone_number}
//                       onChange={e =>
//                         seteditedProviderData({
//                           ...editedProviderData,
//                           phone_number: e.target.value,
//                         })
//                       }
//                       disabled={!editProviderDetail}
//                     />
//                   </FormGroup>
//                   <FormGroup check>
//                     <Label check>
//                       <Input
//                         type="checkbox"
//                         id="is_active"
//                         checked={editedProviderData.is_active}
//                         onChange={e =>
//                           seteditedProviderData({
//                             ...editedProviderData,
//                             is_active: e.target.checked,
//                           })
//                         }
//                         disabled={!editProviderDetail}
//                       />
//                       Is Active
//                     </Label>
//                   </FormGroup>
//                 </>
//               ) : (
//                 <>
//                   <FormGroup>
//                     <Label for="name">Name:</Label>
//                     <Input
//                       type="text"
//                       id="name"
//                       value={providerData.name}
//                       disabled
//                     />
//                   </FormGroup>

//                   <FormGroup>
//                     <Label for="image">Profile image</Label>
//                     <Dropzone
//                       onDrop={acceptedFiles => {
//                         handleAcceptedFiles(acceptedFiles)
//                       }}
//                     >
//                       {({ getRootProps, getInputProps }) => (
//                         <div
//                           className=" "
//                           style={{
//                             backgroundImage: `url(${providerData.photo})`,
//                             // backgroundImage: selectedFiles
//                             //   ? `url(${selectedFiles})`
//                             //   : `url(${providerData.photo})`,

//                             backgroundSize: "cover",
//                             backgroundPosition: "center",
//                             height: "100px", // Profile picture height
//                             width: "100px", // Profile picture width
//                             borderRadius: "50%", // Make it circular
//                             border: "2px solid #ccc", // Add a border for better visibility
//                             display: "flex", // Center content
//                             alignItems: "center", // Center content vertically
//                             justifyContent: "center", // Center content horizontally
//                           }}
//                         >
//                           <div
//                             className="dz-message needsclick"
//                             {...getRootProps()}
//                             style={{
//                               width: "10%",
//                               height: "10%",
//                               display: "flex",
//                               alignItems: "center",
//                               justifyContent: "center",
//                             }}
//                             {...getRootProps()}
//                           >
//                             <input {...getInputProps()} />
//                             {editProviderDetail ? (
//                               <div
//                                 className="mb-3"
//                                 style={{
//                                   position: "relative",
//                                   top: "50px",
//                                   left: "50px",
//                                   background: "rgba(255, 255, 255, 0.7)",
//                                   borderRadius: "50%",
//                                   padding: "5px",
//                                 }}
//                               >
//                                 <i className="mdi mdi-cloud-upload display-6 text-muted"></i>
//                               </div>
//                             ) : null}
//                             {/* <h4>Drop files here or click to upload.</h4> */}
//                           </div>
//                         </div>
//                       )}
//                     </Dropzone>
//                   </FormGroup>

//                   <FormGroup>
//                     <Label for="email">Email:</Label>
//                     <Input
//                       type="email"
//                       id="email"
//                       value={providerData.email}
//                       disabled
//                     />
//                   </FormGroup>
//                   <FormGroup>
//                     <Label for="phone_number">Phone Number:</Label>
//                     <Input
//                       type="tel"
//                       id="phone_number"
//                       value={providerData.phone_number}
//                       disabled
//                     />
//                   </FormGroup>
//                   <FormGroup check>
//                     <Label check>
//                       <Input
//                         type="checkbox"
//                         id="is_active"
//                         checked={providerData.is_active}
//                         disabled
//                       />
//                       Is Active
//                     </Label>
//                   </FormGroup>
//                 </>
//               )}

//               {editProviderDetail ? (
//                 <Row>
//                   <Col sm="1">
//                     <Button
//                       color="primary"
//                       onClick={() => {
//                         setProviderData(editedProviderData)
//                         seteditProviderDetail(false)
//                       }}
//                     >
//                       Save
//                     </Button>
//                   </Col>
//                   <Col sm="1">
//                     <Button
//                       color="danger"
//                       onClick={() => {
//                         seteditedProviderData(providerData)
//                         seteditProviderDetail(false)
//                       }}
//                     >
//                       Cancel
//                     </Button>
//                   </Col>
//                   <Col sm="10">
//                     <div></div>
//                   </Col>
//                 </Row>
//               ) : (
//                 <Row>
//                   <Col sm="1">
//                     <Button
//                       color="primary"
//                       onClick={() => seteditProviderDetail(true)}
//                     >
//                       Edit
//                     </Button>
//                   </Col>
//                   <Col sm="1">
//                     <Button color="danger" onClick={handleEdit}>
//                       Delete
//                     </Button>
//                   </Col>
//                   <Col sm="10">
//                     <div></div>
//                   </Col>
//                 </Row>
//               )}
//             </Form>
//           </div>
//           {/* Ambulance Types */}

//           <div>
//             <h2>Ambulance Types</h2>
//             {providerData.AmbulanceTypes.map(ambulanceType => (
//               <Card>
//                 <CardBody>
//                   <div key={ambulanceType.id}>
//                     <h3>{ambulanceType.name}</h3>
//                     <p>Fare Per KM: {ambulanceType.farePerKm}</p>
//                     <p>Fixed Price: {ambulanceType.fixedPrice}</p>
//                     {isAmbulanceTypeEditing &&
//                     selectedAmbulanceType?.id === ambulanceType.id ? (
//                       <div>
//                         <Form>
//                           <FormGroup>
//                             <Label for="name">Name</Label>
//                             <Input
//                               type="text"
//                               id="name"
//                               name="name"
//                               value={selectedAmbulanceType.name}
//                               onChange={e =>
//                                 setSelectedAmbulanceType({
//                                   ...selectedAmbulanceType,
//                                   name: e.target.value,
//                                 })
//                               }
//                             />
//                           </FormGroup>
//                           <FormGroup>
//                             <Label for="farePerKm">Fare Per KM</Label>
//                             <Input
//                               type="number"
//                               id="farePerKm"
//                               name="farePerKm"
//                               value={selectedAmbulanceType.farePerKm}
//                               onChange={e =>
//                                 setSelectedAmbulanceType({
//                                   ...selectedAmbulanceType,
//                                   farePerKm: e.target.value,
//                                 })
//                               }
//                             />
//                           </FormGroup>
//                           <FormGroup>
//                             <Label for="fixedPrice">Fixed Price</Label>
//                             <Input
//                               type="number"
//                               id="fixedPrice"
//                               name="fixedPrice"
//                               value={selectedAmbulanceType.fixedPrice}
//                               onChange={e =>
//                                 setSelectedAmbulanceType({
//                                   ...selectedAmbulanceType,
//                                   fixedPrice: e.target.value,
//                                 })
//                               }
//                             />
//                           </FormGroup>
//                         </Form>
//                         <Row>
//                           <Col sm="1">
//                             <Button
//                               color="primary"
//                               onClick={() => setIsAmbulanceTypeEditing(false)}
//                             >
//                               Save
//                             </Button>
//                           </Col>
//                           <Col sm="1">
//                             <Button
//                               color="danger"
//                               onClick={() =>
//                                 handleAmbulanceTypeDelete(ambulanceType.id)
//                               }
//                             >
//                               Cancel
//                             </Button>
//                           </Col>
//                           <Col sm="10">
//                             <div></div>
//                           </Col>
//                         </Row>
//                       </div>
//                     ) : (
//                       <div>
//                         <Row>
//                           <Col sm="1">
//                             <Button
//                               color="primary"
//                               onClick={() =>
//                                 handleAmbulanceTypeEdit(ambulanceType)
//                               }
//                             >
//                               Edit
//                             </Button>
//                           </Col>
//                           <Col sm="1">
//                             <Button
//                               color="danger"
//                               onClick={() =>
//                                 handleAmbulanceTypeDelete(ambulanceType.id)
//                               }
//                             >
//                               Delete
//                             </Button>
//                           </Col>
//                           <Col sm="10">
//                             <div></div>
//                           </Col>
//                         </Row>
//                       </div>
//                     )}
//                   </div>
//                 </CardBody>
//               </Card>
//             ))}
//             <Button color="primary" onClick={handleAmbulanceTypeCreate}>
//               Create New Ambulance Type
//             </Button>
//           </div>
//         </Container>
//       </div>
//     </React.Fragment>
//   )
// }

// export default MyProvider

import React, { useState, useEffect } from "react"
import {
  Container,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import Dropzone from "react-dropzone"
import { post, del, get, put } from "../../helpers/api_helper"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useParams, useNavigate } from "react-router-dom"
import user3 from "../../assets/images/users/user-3.jpg"
import { toast } from "react-toastify"
import CreateAmbulanceTypeModal from "../providers/CreateAmbulanceTypeModal"

import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import { storage } from "../../helpers/firebase_storage.js" // Adjust the path as needed
import { GetProviderById } from "helpers/backend_helper"
import { LoadingPage } from "pages/Utility/loading"

const MyProvider = props => {
  const [id, setId] = useState(null)
  const navigate = useNavigate()
  const [providerData, setProviderData] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [editProviderDetail, setEditProviderDetail] = useState(false)
  const [editedProviderData, setEditedProviderData] = useState(null)
  const [isAmbulanceTypeEditing, setIsAmbulanceTypeEditing] = useState(false)
  const [selectedAmbulanceType, setSelectedAmbulanceType] = useState(null)

  async function getId() {
    const user = JSON.parse(localStorage.getItem("authUser"))
    setId(user.provider_id)
  }
  useEffect(() => {
    getId()
  }, [])

  useEffect(() => {
    const fetchManagerData = async () => {
      try {
        const data = await GetProviderById(id)
        setProviderData(data)
        setEditedProviderData(data)
      } catch (error) {
        console.error("Error fetching providers data:", error)
        toast.error("Error fetching providers data")
      }
    }
    if (id != null) {
      fetchManagerData()
    }
  }, [id])

  const handleAcceptedFiles = async acceptedFiles => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0]
      const storageRef = ref(storage, `images/${file.name}`)

      try {
        await uploadBytes(storageRef, file)

        const fileURL = await getDownloadURL(storageRef)

        setEditedProviderData(prevData => ({
          ...prevData,
          photo: fileURL,
        }))
      } catch (error) {
        toast.error("Error uploading file")
      }
    }
  }

  const handleEdit = () => setIsEditing(true)
  const handleSave = async () => {
    try {
      console.log("/////////////////////////////////")
      console.log(editedProviderData)
      setProviderData(editedProviderData)
      setEditProviderDetail(false)
      await put(`/providers/${id}`, editedProviderData)
      toast.success("updated")
    } catch (error) {
      toast.error(error)
    }
  }
  const handleDelete = async () => {
    try {
      setEditedProviderData(providerData)
      setEditProviderDetail(false)
      const res = await del(`/providers/${id}`, {})
      if (res.status == 200) {
        toast.success("deleted")
        setTimeout(() => {
          navigate("/providers")
        }, 1000)
      } else {
        throw Error("error deleting")
      }
    } catch (error) {
      toast.error(error)
    }
  }
  const handleCancel = () => {
    setEditedProviderData(providerData)
    setEditProviderDetail(false)
  }
  const handleAmbulanceTypeEdit = ambulanceType => {
    setSelectedAmbulanceType(ambulanceType)
    setIsAmbulanceTypeEditing(true)
  }

  const handleAmbulanceTypeSave = ambulanceType => {
    setSelectedAmbulanceType(ambulanceType.id)
    setIsAmbulanceTypeEditing(false)
    put(`/ambulance_types/${ambulanceType.id}`, ambulanceType)
  }

  const handleAmbulanceTypeDelete = ambulanceType => {
    setSelectedAmbulanceType(ambulanceType)
    setIsAmbulanceTypeEditing(false)
    del(`/ambulance_types/${ambulanceType.id}`, {})
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalType, setModalType] = useState("")

  const toggleModal = type => {
    setModalType(type)
    setIsModalOpen(!isModalOpen)
  }

  if (!providerData) return <LoadingPage />
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="7">
              <Breadcrumbs
                maintitle="hearts"
                title="Provider"
                // breadcrumbItem={id}
              />
            </Col>
            <Col md="5" className="align-self-end" />
          </Row>

          <div>
            <h2>Provider Details</h2>
            <Form>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="image">Profile image</Label>
                    {editProviderDetail ? (
                      <Dropzone onDrop={handleAcceptedFiles}>
                        {({ getRootProps, getInputProps }) => (
                          <div
                            {...getRootProps()}
                            style={{
                              backgroundImage: `url(${
                                editedProviderData?.photo ||
                                providerData.photo ||
                                user3
                              })`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              height: "100px",
                              width: "100px",
                              borderRadius: "50%",
                              border: "2px solid #ccc",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <input {...getInputProps()} />
                            {editProviderDetail && (
                              <div
                                style={{
                                  position: "relative",
                                  top: "50px",
                                  left: "50px",
                                  background: "rgba(255, 255, 255, 0.7)",
                                  borderRadius: "50%",
                                  padding: "5px",
                                }}
                              >
                                <i className="mdi mdi-cloud-upload display-6 text-muted"></i>
                              </div>
                            )}
                          </div>
                        )}
                      </Dropzone>
                    ) : (
                      <div
                        style={{
                          backgroundImage: `url(${
                            editedProviderData?.photo ||
                            providerData.photo ||
                            user3
                          })`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          height: "100px",
                          width: "100px",
                          borderRadius: "50%",
                          border: "2px solid #ccc",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      />
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="name">Name:</Label>
                    <Input
                      type="text"
                      id="name"
                      value={editedProviderData?.name || ""}
                      onChange={e =>
                        setEditedProviderData({
                          ...editedProviderData,
                          name: e.target.value,
                        })
                      }
                      disabled={!editProviderDetail}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="email">Email:</Label>
                    <Input
                      type="email"
                      id="email"
                      value={editedProviderData?.email || ""}
                      onChange={e =>
                        setEditedProviderData({
                          ...editedProviderData,
                          email: e.target.value,
                        })
                      }
                      disabled={!editProviderDetail}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="phone_number">Phone Number:</Label>
                    <Input
                      type="tel"
                      id="phone_number"
                      value={editedProviderData?.phone_number || ""}
                      onChange={e =>
                        setEditedProviderData({
                          ...editedProviderData,
                          phone_number: e.target.value,
                        })
                      }
                      disabled={!editProviderDetail}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup check className="form-check form-switch mb-3">
                    <Label
                      check
                      className="form-check-label"
                      htmlFor="is_active"
                    >
                      Is Active
                    </Label>
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      id="is_active"
                      checked={editedProviderData?.is_active || false}
                      onClick={e => {
                        console.log("clicked")
                        setEditedProviderData({
                          ...editedProviderData,
                          is_active: e.target.checked,
                        })
                      }}
                      onChange={e => {
                        console.log("changed")
                        setEditedProviderData({
                          ...editedProviderData,
                          is_active: e.target.checked,
                        })
                      }}
                      disabled={!editProviderDetail}
                    />
                  </FormGroup>
                </Col>
              </Row>

              {editProviderDetail ? (
                <Row>
                  <Col sm="1">
                    <Button color="primary" onClick={handleSave}>
                      Save
                    </Button>
                  </Col>
                  <Col sm="1">
                    <Button color="danger" onClick={handleDelete}>
                      Delete
                    </Button>
                  </Col>
                  <Col sm="1">
                    <Button color="warning" onClick={handleCancel}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col sm="1">
                    <Button
                      color="primary"
                      onClick={() => setEditProviderDetail(true)}
                    >
                      Edit
                    </Button>
                  </Col>
                </Row>
              )}
            </Form>
          </div>

          <div>
            <br />
            <h2>Ambulance Types</h2>
            {providerData.AmbulanceTypes &&
              providerData.AmbulanceTypes.map(ambulanceType => (
                <Card key={ambulanceType.id}>
                  <CardBody>
                    <h3>{ambulanceType.name}</h3>
                    <Form>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label for="farePerKm">Fare Per KM:</Label>
                            <Input
                              type="number"
                              id="farePerKm"
                              value={
                                selectedAmbulanceType?.id === ambulanceType.id
                                  ? selectedAmbulanceType.farePerKm
                                  : ambulanceType.farePerKm
                              }
                              onChange={e =>
                                setSelectedAmbulanceType({
                                  ...selectedAmbulanceType,
                                  farePerKm: parseFloat(e.target.value),
                                })
                              }
                              disabled={
                                !(
                                  isAmbulanceTypeEditing &&
                                  selectedAmbulanceType?.id === ambulanceType.id
                                )
                              }
                            />
                          </FormGroup>

                          <FormGroup
                            check
                            className="form-check form-switch mb-3"
                          >
                            <Label
                              check
                              className="form-check-label"
                              htmlFor="is_active"
                            >
                              Is Active
                            </Label>
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              id="is_active"
                              checked={
                                selectedAmbulanceType?.id === ambulanceType.id
                                  ? selectedAmbulanceType.is_active
                                  : ambulanceType.is_active
                              }
                              onClick={e => {
                                // console.log("clicked")
                                if (
                                  selectedAmbulanceType?.id === ambulanceType.id
                                ) {
                                  setSelectedAmbulanceType({
                                    ...selectedAmbulanceType,
                                    is_active: e.target.checked,
                                  })
                                }
                              }}
                              onChange={e => {
                                // console.log("changed")
                                if (
                                  selectedAmbulanceType?.id === ambulanceType.id
                                ) {
                                  setSelectedAmbulanceType({
                                    ...selectedAmbulanceType,
                                    is_active: e.target.checked,
                                  })
                                }
                              }}
                              disabled={
                                !(
                                  isAmbulanceTypeEditing &&
                                  selectedAmbulanceType?.id === ambulanceType.id
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label for="fixedPrice">Fixed Price:</Label>
                            <Input
                              type="number"
                              id="fixedPrice"
                              value={
                                selectedAmbulanceType?.id === ambulanceType.id
                                  ? selectedAmbulanceType.fixedPrice
                                  : ambulanceType.fixedPrice
                              }
                              onChange={e =>
                                setSelectedAmbulanceType({
                                  ...selectedAmbulanceType,
                                  fixedPrice: parseFloat(e.target.value),
                                })
                              }
                              disabled={
                                !(
                                  isAmbulanceTypeEditing &&
                                  selectedAmbulanceType?.id === ambulanceType.id
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {isAmbulanceTypeEditing &&
                      selectedAmbulanceType?.id === ambulanceType.id ? (
                        <Row>
                          <Col sm="1">
                            <Button
                              color="primary"
                              onClick={() =>
                                handleAmbulanceTypeSave(selectedAmbulanceType)
                              }
                            >
                              Save
                            </Button>
                          </Col>
                          <Col sm="1">
                            <Button
                              color="danger"
                              onClick={() =>
                                handleAmbulanceTypeDelete(selectedAmbulanceType)
                              }
                            >
                              Delete
                            </Button>
                          </Col>
                          <Col sm="1">
                            <Button
                              color="danger"
                              onClick={() => setIsAmbulanceTypeEditing(false)}
                            >
                              Cancel
                            </Button>
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          <Col sm="1">
                            <Button
                              color="primary"
                              onClick={() =>
                                handleAmbulanceTypeEdit(ambulanceType)
                              }
                            >
                              Edit
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </Form>
                  </CardBody>
                </Card>
              ))}

            <div className="my-4 text-center">
              <Button
                color="primary"
                onClick={() => toggleModal("scroll")}
                className="m-0"
              >
                Create New Ambulance Type
              </Button>
            </div>
            <CreateAmbulanceTypeModal
              isModalOpen={isModalOpen}
              toggleModal={toggleModal}
              modalType={modalType}
            />
            {/* <Button
              color="primary"
              onClick={() => CreateAmbulanceType({id:id})}
            >
              Create New Ambulance Type
            </Button>  */}
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MyProvider

const CreateAmbulanceType = ({ id }) => {
  const [modal, setModal] = useState(false)
  const [farePerKm, setFarePerKm] = useState("")
  const [fixedPrice, setFixedPrice] = useState("")
  const [name, setName] = useState("ADVANCED")
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggleModal = () => setModal(!modal)
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen)

  const createAmbulanceType = () => {
    const data = {
      farePerKm: parseFloat(farePerKm),
      fixedPrice: parseFloat(fixedPrice),
      name,
    }
    post(`ambulancetypes/create`, { provider_id: id, ...data })
    toggleModal()
  }

  return (
    <div>
      <Button color="primary" onClick={toggleModal}>
        Create Ambulance Type
      </Button>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Create Ambulance Type</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="farePerKm">Fare Per KM</Label>
              <Input
                type="number"
                id="farePerKm"
                value={farePerKm}
                onChange={e => setFarePerKm(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="fixedPrice">Fixed Price</Label>
              <Input
                type="number"
                id="fixedPrice"
                value={fixedPrice}
                onChange={e => setFixedPrice(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="name">Name</Label>
              <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle caret>{name}</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => setName("ADVANCED")}>
                    ADVANCED
                  </DropdownItem>
                  <DropdownItem onClick={() => setName("BASIC")}>
                    BASIC
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={createAmbulanceType}>
            Create
          </Button>{" "}
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
