import React, { useState, useEffect } from "react"
import {
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import {
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap"
import { post, del, get, put } from "../../helpers/api_helper"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"
import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"


import SkeletonRow from "../../components/Common/skeletonRow"
import CreateManagerModal from "./CreateManagerModal"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams,
} from "react-router-dom"
import ReactPaginate from "react-paginate"

function Managers() {
  const [managers, setManagers] = useState([])
  const [roleId, setRoleId] = useState("")
  const [filteredManagers, setFilteredManagers] = useState([])
  const [singlebtn, setSinglebtn] = useState(false)
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedItem, setSelectedItem] = useState([
    <span className="badge bg-primary badge-xl">All</span>,
    0,
  ])
  const [managerStatus, setManagerStatus] = useState(0)
  const [currentManagerPage, setCurrentManagerPage] = useState(1)
  const [totalManagerPages, setTotalManagerPages] = useState(1)
  const [managersPerPage, setManagersPerPage] = useState(12)
  const [all, setAll] = useState(false)
  useEffect(() => {
    const fetchManagers = async () => {
      try {
        const authUser = JSON.parse(localStorage.getItem("authUser"))
        if (authUser.roleId !== "1") {
          const response = await get(
            `/managers?page=${currentManagerPage}&limit=${managersPerPage}&all=${all}&providerId=${authUser.provider_id}`
          )

          setFilteredManagers(response.data)
          setManagers(response.data)
          setTotalManagerPages(response.totalPages)
        } else {
          const response = await get(
            `/managers?page=${currentManagerPage}&limit=${managersPerPage}&all=${all}`
          )
          setFilteredManagers(response.data)
          setManagers(response.data)
          setTotalManagerPages(response.totalPages)
        }
      } catch (error) {
        console.error("Error fetching managers:", error)
        toast.error("Error fetching managers")
      } finally {
        setLoading(false)
      }
    }

    fetchManagers()
  }, [currentManagerPage, managersPerPage])

  const handlePageChange = pageNumber => {
    setCurrentManagerPage(pageNumber)
  }
  const filterManagers = () => {
    return managers.filter(manager => {
      const searchRegex = new RegExp(searchTerm, "i")
      if (managerStatus === 0) {
        return (
          searchTerm === "" ||
          searchRegex.test(manager.name || "") ||
          searchRegex.test(manager.email) ||
          searchRegex.test(manager.phone_number)
        )
      }
      return managerStatus === 1
        ? manager.is_active &&
            (searchTerm === "" ||
              searchRegex.test(manager.name) ||
              searchRegex.test(manager.email) ||
              searchRegex.test(manager.phone_number))
        : !manager.is_active &&
            (searchTerm === "" ||
              searchRegex.test(manager.name) ||
              searchRegex.test(manager.email) ||
              searchRegex.test(manager.phone_number))
    })
  }
  useEffect(() => {
    setFilteredManagers(filterManagers())
  }, [managers, managerStatus, searchTerm])

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalType, setModalType] = useState("")

  async function getRole() {
    const userString = localStorage.getItem("authUser")
    const user = JSON.parse(userString)
    setRoleId(user.roleId)
  }
  useEffect(() => {
    getRole()
  }, [])

  const toggleModal = type => {
    setModalType(type)
    setIsModalOpen(!isModalOpen)
  }
  const handleManagerNew = manager => {
    setManagers([...managers, manager])
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="align-items-center row justify-content-end">
            <Col md="4">
              <Breadcrumbs maintitle="hearts" title="Managers" />
            </Col>
            <Col md="8" className="d-flex justify-content-end">
              {roleId != "3" && (
                <div className="my-4 text-center">
                  <Button
                    color="primary"
                    onClick={() => toggleModal("scroll")}
                    className="m-0"
                  >
                    Create Manager
                  </Button>
                </div>
              )}
              <CreateManagerModal
                isModalOpen={isModalOpen}
                toggleModal={toggleModal}
                modalType={modalType}
                handleManagerNew={handleManagerNew}
              />
            </Col>
          </Row>
          <Row className="relative d-flex align-items-center justify-content-end">
            <Col md="4">
              <Dropdown
                isOpen={singlebtn}
                toggle={() => setSinglebtn(!singlebtn)}
              >
                <DropdownToggle className="btn btn-secondary" caret>
                  {selectedItem[0] || "Status"}{" "}
                  <i className="mdi mdi-chevron-down" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => {
                      setManagerStatus(0)
                      setSelectedItem(["All", 0])
                    }}
                  >
                    <span
                      className="badge bg-primary badge-xl"
                      style={{ fontSize: "1rem" }}
                    >
                      All
                    </span>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setManagerStatus(1)
                      setSelectedItem(["Active", 1])
                    }}
                  >
                    <span
                      className="badge bg-success"
                      style={{ fontSize: "1rem" }}
                    >
                      Active
                    </span>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setManagerStatus(2)
                      setSelectedItem(["Inactive", 2])
                    }}
                  >
                    <span
                      className="badge bg-danger"
                      style={{ fontSize: "1rem" }}
                    >
                      Inactive
                    </span>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>

            <Col md="4">
              <form className="app-search d-block">
                <div className="position-relative">
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                  />
                  <span className="fa fa-search"></span>
                </div>
              </form>
            </Col>
          </Row>

          <Row>
            <Card>
              <CardBody>
                <h4 className="card-title mb-4">Managers</h4>

                <div className="table-responsive">
                  <table className="table table-hover table-centered table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Phone Number</th>
                        <th scope="col">Name</th>
                        <th scope="col">UserName</th>
                        <th scope="col">Email</th>
                        <th scope="col">Role</th>
                        <th scope="col">Status</th>
                        <th scope="col">Provider</th>
                        <th scope="col" colSpan="2">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                       <> {SkeletonRow({ numRows: 4, numColumns: 7 })} </>
                      ) : (
                        <>
                          {filteredManagers &&
                            filteredManagers.length &&
                            filteredManagers.map(manager => (
                              <ManagerRow
                                key={manager.id}
                                id={manager.id}
                                phoneNumber={manager.phone_number}
                                name={manager.name}
                                photo={manager.photo}
                                username={manager.username}
                                email={manager.email}
                                role={manager.role?.name}
                                is_active={manager.is_active}
                                providerName={
                                  manager?.provider?.name || " test"
                                }
                              />
                            ))}
                        </>
                      )}
                    </tbody>
                  </table>
                  <br></br>
                  <div className="d-flex justify-content-center">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="next >"
                      onPageChange={event => handlePageChange(event.selected)}
                      pageRangeDisplayed={3}
                      pageCount={totalManagerPages}
                      previousLabel="< previous"
                      renderOnZeroPageCount={null}
                      containerClassName="pagination"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      activeClassName="active"
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Managers

const ManagerRow = ({
  id,
  phoneNumber,
  photo,
  username,
  name,
  email,
  role,
  providerName,
  is_active,
}) => {
  return (
    <tr>
      <th scope="row">{phoneNumber}</th>
      <th scope="row">{name}</th>
      <td>
        <div>
          <img
            src={photo || user2}
            alt=""
            className="avatar-xs rounded-circle me-2"
            onError={e => (e.target.src = user2)}
          />{" "}
          {username}
        </div>
      </td>
      <td>{email}</td>
      {/* <td>
        <span className={`badge bg-${availability ? "success" : "danger"}`}>
          {availability ? "Active" : "InActive"}
        </span>
      </td> */}
      <td>{role}</td>
      <td>
        <span className={`badge bg-${is_active ? "success" : "danger"}`}>
          {is_active ? "Active" : "Inactive"}
        </span>
      </td>
      <td>{providerName}</td>
      <td>
        <div>
          <Link
            to={`${location.pathname}/${id}`}
            className="btn btn-primary btn-sm"
          >
            Edit
          </Link>
        </div>
      </td>
    </tr>
  )
}
