import React, { useEffect, useState } from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Dropdown,
} from "reactstrap"
// import { toast } from "react-toastify"
import { post, del, get, put } from "../../helpers/api_helper"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { LoadingButton } from "pages/Utility/loading"

const CreateVehicleModal = ({ isModalOpen, toggleModal, modalType, handleVehicleNew }) => {
  const [car_name, setcar_name] = useState("")
  const [plate_number, setplate_number] = useState("")
  const [photo, setphoto] = useState("")
  const [ambulance_type_id, setambulance_type_id] = useState("")
  const [provider_id, setProvider_id] = useState("")
  const [providers, setProviders] = useState([])
  const [ambulances, setambulances] = useState([])

  const [showButton, setShowButton] = useState(true);
  const [logedinRoleId, setlogedinRoleId] = useState("")
  const [logedinProvider, setLogedinProvider] = useState("")

  async function getRole() {
    const userString = localStorage.getItem('authUser');
    const user = JSON.parse(userString);
    if (user.roleId != '1') {
      setProvider_id(user.provider_id)
    }
    setlogedinRoleId(user.roleId)
    setLogedinProvider(user.provider_id)
  }
  useEffect(() => {
    getRole()
  }, [])


  function allFilled() {
    return (
      car_name !== "" &&
      plate_number !== "" &&
      // photo !== "" &&
      ambulance_type_id !== "" &&
      provider_id !== "" &&
      providers !== "" &&
      ambulances !== ""
    )
  }

  async function createVehicle(event) {
    setShowButton(false)
    if (allFilled()) {
      event.preventDefault()
      try {
        const response = await post("/vehicles/create", {
          car_name,
          photo,
          provider_id,
          ambulance_type_id,
          plate_number,
          type: "BASIC",
        })
        console.log(response)
        if (response.status >= 200 && response.status < 300) {
          toast.success("vehicle created successfully!")
          toggleModal(modalType)
          handleVehicleNew(response.data)
        } else {
          console.error("Error creating vehicle:", response.status)
          toast.error("Error creating Manager. Please try again.")
          setShowButton(true)
        }
      } catch (error) {
        console.error("Network error:", error)
        toast.error("Network error. Please try again later.")
        setShowButton(true)
      } finally {
        toggleModal(modalType)
      }
    } else {
      toast.error("Error fill all filleds. Please try again.")
      setShowButton(true)
    }
  }
  // console.log( provider_id)
  function getProviders() {
    get("/providers")
      .then(data => setProviders(data))
      .catch(error => console.error(error))
  }
  async function getambulances() {
    try {
      const data = await get("/ambulance_types")
      const user = JSON.parse(localStorage.getItem('authUser'));
      if (user.roleId != '1') {
        const _filteredData = data.filter((ambulance) => ambulance.provider_id == user.provider_id)
        setambulances(_filteredData)
      }
      else {
        setambulances(data)
      }

    } catch (error) {
      toast.error('error fetching ambulance types')
    }

  }

  useEffect(() => {
    getProviders()
    getambulances()
  }, [])
  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Modal
        isOpen={isModalOpen}
        toggle={() => toggleModal(modalType)}
        size={modalType === "xlarge" ? "xl" : ""}
        scrollable={modalType === "scroll"}
      >
        <ModalHeader toggle={() => toggleModal(modalType)}>
          Vehicle Information
        </ModalHeader>
        <ModalBody>
          <form>

            {logedinRoleId == "1" ? (<div className="mb-3">
              <label htmlFor="provider_id" className="form-label">
                Provider *
              </label>
              <select
                className="form-control"
                id="provider_id"
                value={provider_id}
                onChange={e => setProvider_id(e.target.value)}
              >
                <option value="">Select a provider</option>
                {providers &&
                  providers.map(pro => (
                    <option key={pro?.id} value={pro?.id}>
                      {pro?.name}
                    </option>
                  ))}
              </select>
            </div>) : null}


            <div className="mb-3">
              <label htmlFor="car_name" className="form-label">
                car name *
              </label>
              <input
                required
                type="text"
                className="form-control"
                id="name"
                value={car_name}
                onChange={ev => setcar_name(ev.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="plate_number" className="form-label">
                plate number *
              </label>
              <input
                required
                type="plate_number"
                className="form-control"
                id="plate_number"
                value={plate_number}
                onChange={ev => setplate_number(ev.target.value)}
              />
            </div>
            {/* <div className="mb-3">
              <label htmlFor="photo" className="form-label">
                photo
              </label>
              <input
                required
                type="plate_number"
                className="form-control"
                id="plate_number"
                value={photo}
                onChange={ev => setphoto(ev.target.value)}
              />
            </div> */}
            <div className="mb-3">
              <label htmlFor="phone_number" className="form-label">
                select ambulance type *
              </label>
              <select
                className="form-control"
                id="provider_id"
                value={ambulance_type_id}
                onChange={e => setambulance_type_id(e.target.value)}
              >
                <option value="">Select a Ambulance type</option>
                {Array.isArray(ambulances) &&
                  ambulances.length > 0 &&
                  ambulances.map(pro => (
                    <option key={pro?.id} value={pro?.id}>
                      {pro?.name}, {pro.farePerKm}birr/km, {pro.fixedPrice}{" "}
                      fixed price
                    </option>
                  ))}
              </select>
            </div>

            <ModalFooter>
              <Button color="secondary" onClick={() => toggleModal(modalType)}>
                Close
              </Button>
              {showButton ? <Button color="primary" type="submit" onClick={createVehicle}>
                Add Vehicle
              </Button> : <LoadingButton /> }
      
            </ModalFooter>
          </form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default CreateVehicleModal
