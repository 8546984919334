import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap"
import { toast } from "react-toastify"
import { post, del, get, put } from "../../helpers/api_helper"

import { useParams } from "react-router-dom"
import MiniMap from "./MiniMap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import user3 from "../../assets/images/users/user-3.jpg"
import { GetDrivers, UpdateBooking } from "helpers/backend_helper"
import { LoadingPage } from "pages/Utility/loading"
const BookingsSlug = () => {
  const { id } = useParams()
  const [booking, setBooking] = useState(null)
  const [drivers, setdrivers] = useState(null)
  const [editMode, setEditMode] = useState(false)
  const [editDriverMode, setEditDriverMode] = useState(false)

  const [isOpen, setIsOpen] = useState(true)

  const handleStatusChange = async event => {
    setBooking(prevBooking => ({
      ...prevBooking,
      status: event.target.value,
    }))
  }

  const handleDriverChange = async event => {
    setBooking(prevBooking => ({
      ...prevBooking,
      driver_id: event.id,
      driver: event,
    }))
  }
  const handleSaveStatusChanges = async () => {
    // Save the updated booking data
    console.log("Saving changes:", booking)
    setEditMode(false)
    try {
      await UpdateBooking(id, booking)
      toast.success("Status updated")
    } catch (error) {
      toast.error("could not update Status ")
    }
  }

  const handleSaveDriverChanges = async () => {
    // Save the updated booking data
    console.log("Saving changes:", booking)
    setEditMode(false)
    try {
      await UpdateBooking(id, booking)
      toast.success("Driver updated")
    } catch (error) {
      toast.error("could not update Driver ")
    }
  }

  useEffect(() => {
    GetDrivers({ params: { is_active: true, all: true } })
      .then(({ data }) => setdrivers(data))
      .catch(error => console.error(error))
  }, [])
  useEffect(() => {
    // Fetch the manager data from the server or other data source
    const fetchBookingData = async () => {
      if (id != null)
        try {
          const response = await get(`/bookings/${id}`)
          const data = await response
          setBooking(data)
        } catch (error) {
          console.error("Error fetching booking data:", error)
          toast.error("Error fetching booking data")
        }
    }
    fetchBookingData()
  }, [id])

  if (!booking) {
    return <LoadingPage />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="7">
              <Breadcrumbs maintitle="hearts" title="Booking" />
            </Col>
            <Col md="5" className="align-self-end" />
          </Row>

          <h1>Booking Details</h1>
          <Row>
            <MiniMap booking={booking} google={window.google} />
          </Row>

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4>Booking Details</h4>
                  {/* Client details */}
                  <p>Distance: {booking?.distance}</p>
                  <p>Estimated Price: {booking?.price}</p>
                  <p>createdAt: {booking?.createdAt}</p>
                </CardBody>
              </Card>
            </Col>

            <Col>
              <Card>
                <CardBody>
                  <h4>Booking Details</h4>
                  <Form>
                    <FormGroup>
                      <Label for="status">Status</Label>
                      <Input
                        type="select"
                        id="status"
                        name="status"
                        value={booking.status}
                        onChange={handleStatusChange}
                        disabled={!editMode}
                      >
                        <option value="PENDING">Pending</option>
                        <option value="ACCEPTED">ACCEPTED</option>
                        <option value="ARRIVED">ARRIVED</option>
                        <option value="STARTED">STARTED</option>
                        <option value="COMPLETED">Completed</option>
                        <option value="CANCELLED">Cancelled</option>
                        <option value="EXPIRED">EXPIRED</option>
                        <option value="REJECTED">REJECTED</option>
                      </Input>
                    </FormGroup>

                    {/* Add other booking details here */}
                    {editMode ? (
                      <>
                        <Button
                          color="primary"
                          onClick={handleSaveStatusChanges}
                        >
                          Save Changes
                        </Button>
                        {"  "}
                        <Button
                          color="danger"
                          onClick={() => setEditMode(false)}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <Button color="primary" onClick={() => setEditMode(true)}>
                        Edit
                      </Button>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4>Client Details</h4>
                  {/* Client details */}
                  <p>Phone Number: {booking?.client?.phone_number}</p>
                  <p>Username: {booking?.client?.username}</p>
                  <p>Email: {booking?.client?.email}</p>
                  {/* <p>Photo: {booking.client.photo}</p> */}
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  <h4>Driver Details</h4>
                  {/* Driver details */}
                  <p>Phone Number: {booking.driver?.phone_number}</p>
                  <p>Name: {booking.driver?.name}</p>
                  <p>Email: {booking.driver?.email}</p>
                  {/* <p>Photo: {booking.driver.photo}</p> */}

                  {/* <p>Vehicle : {booking.driver?.vehicle?.plate_number}</p> 
                  <p>Vehicle : {booking.driver?.vehicle?.plate}</p>  */}
                  {/* {editDriverMode ? (
                    <>
                      <div className="mb-3">
                        <label htmlFor="booking" className="form-label">
                          Available Drivers
                        </label>
                        <select
                          className="form-control"
                          id="provider_id"
                          value={booking.driver_id}
                          onChange={handleDriverChange}
                        >
                          <option value="">Select a driver</option>
                           {drivers &&
                            drivers.map(driver => (
                              <option key={driver?.id} value={driver?.id}>
                                <div className="d-flex align-items-center">
                                  <img
                                    src={driver.photo}
                                    alt={driver.name}
                                    className="me-2"
                                    style={{ maxWidth: "40px" }}
                                  />
                                  <div>
                                    <div>{driver?.name}</div>
                                    <div>{driver.vehicle.car_name}</div>
                                  </div>
                                </div>
                              </option>
                            ))} 
                      
                        </select>
                      </div>
                      <Button color="primary" onClick={handleSaveDriverChanges}>
                        Save Changes
                      </Button>
                      {"  "}
                      <Button
                        color="danger"
                        onClick={() => setEditDriverMode(false)}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <Button
                      color="primary"
                      onClick={() => setEditDriverMode(true)}
                    >
                      Edit
                    </Button>
                  )} */}
                  <>
                    {editDriverMode ? (
                      <div className="mt-2">
                        <div className="mb-3">
                          <label htmlFor="booking" className="form-label">
                            Available Drivers
                          </label>
                          <div
                            onClick={e => {
                              e.preventDefault()
                              setIsOpen(!isOpen)
                            }}
                          >
                            {isOpen ? (
                              <ul className="list-group">
                                {Array.isArray(drivers) &&
                                  drivers.length > 0 &&
                                  drivers.map(driver => (
                                    <li
                                      key={driver.id}
                                      className={`list-group-item ${
                                        driver.id === booking.driver_id
                                          ? "active"
                                          : ""
                                      } mb-3`}
                                      onClick={() => {
                                        handleDriverChange(driver)
                                        setIsOpen(false)
                                      }}
                                    >
                                      <div className="d-flex align-items-center">
                                        <img
                                          src={driver?.photo || user3}
                                          alt={driver?.username}
                                          className="avatar-sm rounded-circle me-2"
                                          onError={e => {
                                            e.target.src = user3
                                          }}
                                        />
                                        <div>
                                          <h6 className="mb-0">
                                            {driver?.name}
                                          </h6>
                                          <h6 className="mb-0">
                                            {driver?.username}
                                          </h6>
                                          <small className="text-muted">
                                            {driver?.phone_number}
                                          </small>
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                              </ul>
                            ) : (
                              <div className="d-flex align-items-center">
                                <span className="btn btn-primary">
                                  Select a driver
                                </span>
                                <i className="bi bi-chevron-down ms-auto"></i>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          <button
                            className="btn btn-primary"
                            onClick={handleSaveDriverChanges}
                          >
                            Save Changes
                          </button>
                          <button
                            className="btn btn-danger ms-2"
                            onClick={() => {
                              setEditDriverMode(false)
                              setIsOpen(false)
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    ) : (
                      <Button
                        color="primary"
                        onClick={() => setEditDriverMode(true)}
                      >
                        Edit
                      </Button>
                    )}
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4>Pickup Location</h4>
                  {/* Pickup location details */}
                  <p>Address: {booking?.pickup_location?.address}</p>
                  <Row>
                    <Col>
                      <p>Latitude: {booking?.pickup_location?.latitude}</p>
                    </Col>
                    <Col>
                      <p>Longitude: {booking?.pickup_location?.longitude}</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  <h4>Dropoff Location</h4>
                  {/* Dropoff location details */}
                  <p>Address: {booking?.dropoff_location?.address}</p>
                  <Row>
                    <Col>
                      <p>Latitude: {booking?.dropoff_location?.latitude}</p>
                    </Col>
                    <Col>
                      <p>Longitude: {booking?.dropoff_location?.longitude}</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4>Review Details</h4>
                  {/* Review details */}
                  <p>Rating: {booking?.review?.rating}</p>
                  <p>Comment: {booking?.review?.comment}</p>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  <h4>Cancellation Details</h4>
                  {/* Review details */}
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  <h4>Payment Details</h4>
                  {/* Review details */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BookingsSlug
