import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardLink,
} from "reactstrap"
import { useParams, useNavigate, Link } from "react-router-dom"
import Dropzone from "react-dropzone"
import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"
import { toast } from "react-toastify"
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { post, del, get, put } from "../../helpers/api_helper"
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import { storage } from "../../helpers/firebase_storage.js" // Adjust the path as needed
import { LoadingPage } from "pages/Utility/loading"

function ManagersSlug() {
  const { id } = useParams()
  const navigate = useNavigate()
  const [managerData, setManagerData] = useState(null)
  const [isEditing, setIsEditing] = useState(false)

  const [editManagerDetail, setEditManagerDetail] = useState(false)
  const [editedManagerData, setEditedManagerData] = useState(null)

  useEffect(() => {
    const fetchManagerData = async () => {
      try {
        const data = await get(`/managers/${id}`)
        setManagerData(data)
        setEditedManagerData(data)
        console.log("/////////////////////////////")
        console.log(data)
      } catch (error) {
        console.error("Error fetching Managers data:", error)
        toast.error("Error fetching Managers data")
      }
    }
    fetchManagerData()
  }, [id])

  const handleAcceptedFiles = async acceptedFiles => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0]
      const storageRef = ref(storage, `images/${file.name}`)

      try {
        await uploadBytes(storageRef, file)

        const fileURL = await getDownloadURL(storageRef)

        setEditedManagerData(prevData => ({
          ...prevData,
          photo: fileURL,
        }))
      } catch (error) {
        toast.error("Error uploading file")
      }
    }
  }

  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleSave = async () => {
    try {
      console.log("/////////////////////////////////")
      console.log(editedManagerData)
      setEditedManagerData(editedManagerData)
      setEditManagerDetail(false)
      const res = await put(`/managers/${id}`, editedManagerData)
      if (res.status == 200) {
        toast.success("updated")
      }
    } catch (error) {
      toast.error(error)
    }
  }

  const handleDelete = async () => {
    try {
      setEditedManagerData(managerData)
      setEditManagerDetail(false)
      const res = await del(`/managers/${id}`, {})
      if (res.status == 200) {
        toast.success("deleted")
        setTimeout(() => {
          navigate("/managers")
        }, 1000)
      } else {
        throw Error("error deleting")
      }
    } catch (error) {
      toast.error(error)
    }
  }

  const handleCancel = () => {
    setEditedManagerData(managerData)
    setEditManagerDetail(false)
  }

  const handleProviderClick = () => {
    navigate(`/managers/${managerData.provider.id}`)
  }

  if (!managerData) {
    return <LoadingPage />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="7">
              <Breadcrumbs maintitle="hearts" title="Manager" />
            </Col>
            <Col md="5" className="align-self-end" />
          </Row>

          <Row>
            <Col>
              <h2>Manager Details</h2>
              <Form>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="image">Profile image</Label>
                      {editManagerDetail ? (
                        <Dropzone onDrop={handleAcceptedFiles}>
                          {({ getRootProps, getInputProps }) => (
                            <div
                              {...getRootProps()}
                              style={{
                                backgroundImage: `url(${
                                  editedManagerData?.photo ||
                                  managerData.photo ||
                                  user3
                                })`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                height: "100px",
                                width: "100px",
                                borderRadius: "50%",
                                border: "2px solid #ccc",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <input {...getInputProps()} />
                              {editManagerDetail && (
                                <div
                                  style={{
                                    position: "relative",
                                    top: "50px",
                                    left: "50px",
                                    background: "rgba(255, 255, 255, 0.7)",
                                    borderRadius: "50%",
                                    padding: "5px",
                                  }}
                                >
                                  <i className="mdi mdi-cloud-upload display-6 text-muted"></i>
                                </div>
                              )}
                            </div>
                          )}
                        </Dropzone>
                      ) : (
                        <div
                          style={{
                            backgroundImage: `url(${
                              editedManagerData?.photo ||
                              managerData.photo ||
                              user3
                            })`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            height: "100px",
                            width: "100px",
                            borderRadius: "50%",
                            border: "2px solid #ccc",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        />
                      )}
                    </FormGroup>
                    {/*<FormGroup>
                      <Label for="provider">Provider: {"   "}</Label>
                      {/* {managerData?.provider && (
                        <CardLink onClick={handleProviderClick}>
                          {` ${managerData?.provider?.name}`}
                        </CardLink>
                      )} 
                      <Input
                        type="text"
                        id="provider"
                        value={editedManagerData?.provider || ""}
                        onChange={e =>
                          setEditedManagerData({
                            ...editedManagerData,
                            provider: e.target.value,
                          })
                        }
                        disabled={!editManagerDetail}
                      />
                    </FormGroup>*/}
                    <FormGroup>
                      <Label for="role">Role: {"   "}</Label>
                      {`       ${managerData.role?.name}`}
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="phone_number">Phone Number</Label>
                      <Input
                        type="tel"
                        id="phone_number"
                        value={editedManagerData?.phone_number || ""}
                        onChange={e =>
                          setEditedManagerData({
                            ...editedManagerData,
                            phone_number: e.target.value,
                          })
                        }
                        disabled={!editManagerDetail}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="username">Username</Label>
                      <Input
                        type="text"
                        id="username"
                        value={editedManagerData?.username || ""}
                        onChange={e =>
                          setEditedManagerData({
                            ...editedManagerData,
                            username: e.target.value,
                          })
                        }
                        disabled={!editManagerDetail}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="name">Name</Label>
                      <Input
                        type="text"
                        id="name"
                        value={editedManagerData?.name || ""}
                        onChange={e =>
                          setEditedManagerData({
                            ...editedManagerData,
                            name: e.target.value,
                          })
                        }
                        disabled={!editManagerDetail}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="email">Email</Label>
                      <Input
                        type="text"
                        id="email"
                        value={editedManagerData?.email || ""}
                        onChange={e =>
                          setEditedManagerData({
                            ...editedManagerData,
                            email: e.target.value,
                          })
                        }
                        disabled={!editManagerDetail}
                      />
                    </FormGroup>

                    <FormGroup check className="form-check form-switch mb-3">
                      <Label
                        check
                        className="form-check-label"
                        htmlFor="is_active"
                      >
                        Is Active
                      </Label>

                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="is_active"
                        checked={editedManagerData?.is_active || false}
                        onClick={e => {
                          console.log("clicked")
                          setEditedManagerData({
                            ...editedManagerData,
                            is_active: e.target.checked,
                          })
                        }}
                        onChange={e => {
                          console.log("changed")
                          setEditedManagerData({
                            ...editedManagerData,
                            is_active: e.target.checked,
                          })
                        }}
                        disabled={!editManagerDetail}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                {/* Other form fields... */}
                {editManagerDetail ? (
                  <Row>
                    <Col sm="1">
                      <Button color="primary" onClick={handleSave}>
                        Save
                      </Button>
                    </Col>
                    <Col sm="1">
                      <Button color="danger" onClick={handleDelete}>
                        Delete
                      </Button>
                    </Col>
                    <Col sm="1">
                      <Button color="warning" onClick={handleCancel}>
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col sm="1">
                      <Button
                        color="primary"
                        onClick={() => setEditManagerDetail(true)}
                      >
                        Edit
                      </Button>
                    </Col>
                  </Row>
                )}
              </Form>
            </Col>
          </Row>

          <br />
          <Row>
            <Col md={4}>
              <Card>
                <CardBody>
                  <CardTitle tag="h5">Provider</CardTitle>
                  <CardText>
                    provider: {managerData?.provider?.name}
                    <br />
                    <Link to={`/providers/${managerData?.provider?.id}`}>
                      view provider details
                    </Link>
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ManagersSlug
