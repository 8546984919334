// src/components/SocketListener.js
// import { useEffect,useState } from "react"
// import { useSocket } from "./SocketContext"
// import { toast } from "react-toastify"
// import CreateBookingModal from "./components/Common/ComingSoonModal"
// const SocketListener = () => {
//   const socket = useSocket()

//   const [isModalOpen, setIsModalOpen] = useState(false)
//   const [modalType, setModalType] = useState("")

//   const toggleModal = type => {
//     setModalType(type)
//     setIsModalOpen(!isModalOpen)
//   }
//   useEffect(() => {
//     if (socket) {

//       //   socket.on('position_change', (data) => {
//       //     console.log('Position changed:', data);
//       //     toast.success('Position updated!');
//       //   });

//       socket.on("new_booking", data => {
//         console.log("New booking:", data)
//         toggleModal("xlarge")

//         toast.success("New booking received!")
//       })

//       socket.on("booking_update", data => {
//         console.log("Booking updated:", data)
//         // toast.success("Booking updated!")
//       })

//       return () => {
//         socket.off("position_change")
//         socket.off("new_booking")
//         socket.off("booking_update")
//       }
//     }
//   }, [socket])

//   return (
//     <>
//       <CreateBookingModal
//         isModalOpen={isModalOpen}
//         toggleModal={toggleModal}
//         modalType={modalType}
//       />
//     </>
//   )
// }

// export default SocketListener

// src/components/SocketListener.js
import { useEffect, useState } from "react"
import { useSocket } from "./SocketContext"
import { toast } from "react-toastify"
import IncomingBookingModal from "./components/Common/IncomingBookingModal"
const SocketListener = () => {
  const socket = useSocket()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalType, setModalType] = useState("")
  const [booking, setBooking] = useState(null)

  const toggleModal = (type, bookingData) => {
    setModalType(type)
    setBooking(bookingData)
    setIsModalOpen(!isModalOpen)
  }

  useEffect(() => {
    if (socket) {
      socket.on("new_booking", data => {
        console.log("New booking:", data)
        const authUser = JSON.parse(localStorage.getItem("authUser"))
        if (
          data.status == "PENDING" &&
          authUser.provider_id == data.provider_id
        ) {
          playNotificationSound()
          // toast.success("New booking received!")
          // Check if the browser window is in focus
          if (document.visibilityState !== "visible") {
            // Request permission if needed
            if (Notification.permission === "default") {
              Notification.requestPermission().then(permission => {
                if (permission === "granted") {
                  showNotification(data)
                }
              })
            } else if (Notification.permission === "granted") {
              showNotification(data)
            }
          } else {
            toggleModal("xlarge", data)
          }
        }
      })

      socket.on("booking_update", data => {
        console.log("Booking updated:", data)
        // toast.success("Booking updated!")
      })

      return () => {
        socket.off("position_change")
        socket.off("new_booking")
        socket.off("booking_update")
      }
    }
  }, [socket])
  function showNotification(data) {
    const options = {
      body: `New booking from ${data.driver.name} to ${
        data.distance / 1000
      } km away`, // Notification body
      icon: "/logo.png", // Path to your icon
    }
    const notification = new Notification("New Booking", options)

    // Optionally, handle notification click event
    notification.onclick = () => {
      window.focus()
      stopNotificationSound()
      toggleModal("xlarge", data)
    }
  }

function playNotificationSound() {
  try {
    const audio = new Audio("/alert.mp3"); // Path to your custom sound
    audio
      .play()
      .catch(error =>
        console.error("Error playing notification sound:", error)
      );
    // Store the audio object for later use
    window.notificationSound = audio;
  } catch (error) {
    console.error("Error initializing notification sound:", error);
  }
}

function stopNotificationSound() {
  if (window.notificationSound) {
    window.notificationSound.pause();
    window.notificationSound.currentTime = 0; // Reset the sound
  }
}
  return (
    <>
      <IncomingBookingModal
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        modalType={modalType}
        booking={booking}
      />
    </>
  )
}

export default SocketListener
